"use strict";

class SSEoiService {

    constructor($sce, $timeout, SSConfigService, SSAlertService, SSHttpService, SSAwsService, SSUtilService) {
        const self = this;

        self.$sce = $sce;
        self.$timeout = $timeout;
        self.SSConfigService = SSConfigService;
        self.SSAlertService = SSAlertService;
        self.SSHttpService = SSHttpService;
        self.SSAwsService = SSAwsService;
        self.SSUtilService = SSUtilService;

        self.PROPERTY_DOCUMENT_TYPE_FORM = SHOWSUITE.PROPERTY_DOCUMENT_TYPE_FORM;
        self.PROPERTY_DOCUMENT_TYPE_USER_DOC = SHOWSUITE.PROPERTY_DOCUMENT_TYPE_USER_DOC;
        self.PROPERTY_DOCUMENT_FORMAT_PDF = SHOWSUITE.PROPERTY_DOCUMENT_FORMAT_PDF;
        self.PROPERTY_DOCUMENT_FORMAT_IMG = SHOWSUITE.PROPERTY_DOCUMENT_FORMAT_IMG;

        self.PROPERTY_DOCUMENT_KIND_NRIC_FRONT = SHOWSUITE.PROPERTY_DOCUMENT_KIND_NRIC_FRONT;
        self.PROPERTY_DOCUMENT_KIND_NRIC_BACK = SHOWSUITE.PROPERTY_DOCUMENT_KIND_NRIC_BACK;
        self.PROPERTY_DOCUMENT_KIND_PASSPORT = SHOWSUITE.PROPERTY_DOCUMENT_KIND_PASSPORT;
        self.PROPERTY_DOCUMENT_KIND_COMPANY_CERTIFICATE = SHOWSUITE.PROPERTY_DOCUMENT_KIND_COMPANY_CERTIFICATE;
        self.PROPERTY_DOCUMENT_KIND_LETTER_OF_AUTHORISATION = SHOWSUITE.PROPERTY_DOCUMENT_KIND_LETTER_OF_AUTHORISATION;
        self.PROPERTY_DOCUMENT_KIND_OTHER = SHOWSUITE.PROPERTY_DOCUMENT_KIND_OTHER;

        self.PROPERTY_DOCUMENT_KIND_BUYER_PHOTO = SHOWSUITE.PROPERTY_DOCUMENT_KIND_BUYER_PHOTO;
        self.ASSET_MODEL_BUYER_DOCUMENT = SHOWSUITE.ASSET_MODEL_BUYER_DOCUMENT;

        self.QUESTION_GROUP_INDIVIDUAL = SHOWSUITE.QUESTION_GROUP_INDIVIDUAL;
        self.QUESTION_GROUP_COMPANY = SHOWSUITE.QUESTION_GROUP_COMPANY;
        self.QUESTION_GROUP_SIGNATORY = SHOWSUITE.QUESTION_GROUP_SIGNATORY;
        self.QUESTION_GROUP_BOTH_INDIVIDUAL_N_COMPANY = SHOWSUITE.QUESTION_GROUP_BOTH_INDIVIDUAL_N_COMPANY;  //  40
        self.QUESTION_GROUP_BOTH_INDIVIDUAL_N_SIGNATORY = SHOWSUITE.QUESTION_GROUP_BOTH_INDIVIDUAL_N_SIGNATORY;  //  50

        self.QUESTION_ENTITY_TYPE_BROKER_TEAM_LEAD = SHOWSUITE.QUESTION_ENTITY_TYPE_BROKER_TEAM_LEAD;
        self.QUESTION_ENTITY_TYPE_DEVELOPER = SHOWSUITE.QUESTION_ENTITY_TYPE_DEVELOPER;


        self.SEARCH_BUYER_PAGE = 0;
        self.UNIT_SELECTION_PAGE = 1;
        self.BUYER_PARTICULAR_PAGE = 2;
        self.DOCUMENT_UPLOAD_PAGE = 3;
        self.CO_BROKER_SELECTION_PAGE = 4;
        self.EOI_SUMMARY_PAGE = 5;
        self.EOI_CONFIRMATION_PAGE = 6;

        //Launch Day
        self.LAUNCH_TERM_AND_CONDITIONS_PAGE = 0;
        self.LAUNCH_UNIT_SELECTION_PAGE = 1;
        self.LAUNCH_BUYER_PARTICULAR_PAGE = 2;
        self.LAUNCH_SELECTION_PAGE = 3;
        self.LAUNCH_PDI_PAGE = 4;
        self.LAUNCH_DOCUMENT_UPLOAD_PAGE = 5;
        self.LAUNCH_CO_BROKER_SELECTION_PAGE = 6;
        self.LAUNCH_SUMMARY_PAGE = 7;
        self.LAUNCH_CONFIRMATION_PAGE = 8;

        // New page for 'DMS: ADD SALE'
        self.LAUNCH_ADD_DISCOUNTS_PAGE = 99;

        self.SINGAPORE_SELECTOR_ID = 702;

        self.individual_relationships = [
            {
                id: 1,
                name: 'SPOUSE'
            },
            {
                id: 2,
                name: 'PARENT'
            },
            {
                id: 3,
                name: 'SIBLING'
            },
            {
                id: 4,
                name: 'CHILD'
            },
            {
                id: 5,
                name: 'ADOPTED CHILD'
            },
            {
                id: 6,
                name: 'STEP-CHILD'
            },
            {
                id: 7,
                name: 'GRANDFATHER'
            },
            {
                id: 8,
                name: 'GRANDMOTHER'
            },
            {
                id: 9,
                name: 'GRANDCHILDREN'
            },
            {
                id: 10,
                name: 'UNCLE'
            },
            {
                id: 11,
                name: 'AUNT'
            },
            {
                id: 12,
                name: 'FATHER-IN-LAW'
            },
            {
                id: 13,
                name: 'MOTHER-IN-LAW'
            },
            {
                id: 14,
                name: 'BROTHER-IN-LAW'
            },
            {
                id: 15,
                name: 'SISTER-IN-LAW'
            },
            {
                id: 16,
                name: 'DAUGHTER-IN-LAW'
            },
            {
                id: 17,
                name: "SIBLING'S BROTHER-IN-LAW"
            },
            {
                id: 18,
                name: "SIBLING'S SISTER-IN-LAW"
            },
            {
                id: 19,
                name: 'NEPHEW'
            },
            {
                id: 20,
                name: 'NIECE'
            },
            {
                id: 21,
                name: 'COUSIN'
            },
            {
                id: 22,
                name: 'FRIEND'
            },
            {
                id: 23,
                name: 'COMPANY'
            },
            {
                id: 24,
                name: 'CO-WORKER'
            },
            {
                id: 25,
                name: 'BUSINESS PARTNER'
            },
            {
                id: 26,
                name: 'ASSOCIATE'
            },
            {
                id: 27,
                name: 'FIANCE'
            },
            {
                id: 28,
                name: 'FIANCEE'
            },
            {
                id: 29,
                name: 'BOYFRIEND'
            },
            {
                id: 30,
                name: 'GIRLFRIEND'
            },
            {
                id: 31,
                name: 'ACQUAINTANCE'
            },
            {
                id: 99,
                name: 'OTHERS'
            }
        ];

        self.corporate_relationships = self.individual_relationships;

        self.types = [
            {
                id: 10,
                name: 'INDIVIDUAL'
            },
            {
                id: 20,
                name: 'CORPORATE'
            }
        ];

        self.individual_types = [
            {
                id: 10,
                name: 'INDIVIDUAL'
            }
        ];

        self.genders = [];
        self.countries = [];
        self.nationalities = [];
        self.maritalStatuses = [];
        self.residence_types = [];
        self.EMPLOYMENT_STATUSES = [];
        self.EDUCATION_LEVELS = [];

        self.industry_occupation = {
            'ACCOUNTING' : {
                    id: 1,
                    name: 'ACCOUNTING',
                    occupation: {
                        'ACCOUNTANT' : {
                            id: 1,
                            name: 'ACCOUNTANT'
                        },
                        'ACCOUNTING CLERK / SUPERVISOR' : {
                            id: 2,
                            name: 'ACCOUNTING CLERK / SUPERVISOR'
                        },
                        'AUDIT' : {
                            id: 3,
                            name: 'AUDIT'
                        },
                        'CHIEF ACCOUNTANT' : {
                            id: 4,
                            name: 'CHIEF ACCOUNTANT'
                        },
                        'CONSULTING' : {
                            id: 5,
                            name: 'CONSULTING'
                        },
                        'CREDIT CONTROL' : {
                            id: 6,
                            name: 'CREDIT CONTROL'
                        },
                        'FINANCE / ACCOUNTING MANAGER' : {
                            id: 7,
                            name: 'FINANCE / ACCOUNTING MANAGER'
                        },
                        'FINANCIAL ANALYST' : {
                            id: 8,
                            name: 'FINANCIAL ANALYST'
                        },
                        'Financial Controller' : {
                            id: 9,
                            name: 'FINANCIAL CONTROLLER'
                        },
                        'TAXATION' : {
                            id: 10,
                            name: 'TAXATION'
                        },
                        'TREASURER' : {
                            id: 11,
                            name: 'TREASURER'
                        },
                        'OTHERS' : {
                            id: 999,
                            name: 'OTHERS'
                        }
                    }
            },
            'ADMIN / HUMAN RESOURCES' : {
                    id: 2,
                    name: 'ADMIN / HUMAN RESOURCES',
                    occupation: {
                        12 : {
                            id: 12,
                            name: 'ADMINISTRATION / OPERATION MANAGER'
                        },
                        13 : {
                            id: 13,
                            name: 'CLERICAL / ADMIN STAFF'
                        },
                        14 : {
                            id: 14,
                            name: 'COMPENSATION & BENEFITS'
                        },
                        15 : {
                            id: 15,
                            name: 'HR DIRECTOR / MANAGER'
                        },
                        16 : {
                            id: 16,
                            name: 'HR OFFICER / EXECUTIVE'
                        },
                        17 : {
                            id: 17,
                            name: 'RECEPTIONIST'
                        },
                        18 : {
                            id: 18,
                            name: 'RECRUITMENT / EXECUTIVE SEARCH'
                        },
                        19 : {
                            id: 19,
                            name: 'SECRETARY / PERSONAL ASSISTANT'
                        },
                        20 : {
                            id: 20,
                            name: 'TRAINING & DEVELOPMENT'
                        },
                        999 : {
                            id: 999,
                            name: 'OTHERS'
                        }
                    }
            },
            'BANKING / FINANCE' : {
                    id: 3,
                    name: 'BANKING / FINANCE',
                    occupation: {
                            21 : {
                                id: 21,
                                name: 'ANALYSIS / CREDIT ANALYSIS / APPROVAL'
                            },
                            22 : {
                                id: 22,
                                name: 'ANALYST'
                            },
                            23 : {
                                id: 23,
                                name: 'CORPORATE BANKING'
                            },
                            24 : {
                                id: 24,
                                name: 'CORPORATE FINANCE'
                            },
                            25 : {
                                id: 25,
                                name: 'CREDIT COLLECTION'
                            },
                            26 : {
                                id: 26,
                                name: 'DEALING & TRADING'
                            },
                            27 : {
                                id: 27,
                                name: 'EQUITIES / CAPITAL MARKETS / SECURITES'
                            },
                            28 : {
                                id: 28,
                                name: 'FINANCIAL SERVICES'
                            },
                            29 : {
                                id: 29,
                                name: 'FUND MANAGEMENT'
                            },
                            30 : {
                                id: 30,
                                name: 'INVESTMENT'
                            },
                            31 : {
                                id: 31,
                                name: 'LOAN / MORTGAGE'
                            },
                            32 : {
                                id: 32,
                                name: 'ORDER PROCESSING & OPERATION / SETTLEMENT'
                            },
                            33 : {
                                id: 33,
                                name: 'PRIVATE BANKING'
                            },
                            34 : {
                                id: 34,
                                name: 'PROJECT FINANCE '
                            },
                            35 : {
                                id: 35,
                                name: 'RETAIL BANKING'
                            },
                            36 : {
                                id: 36,
                                name: 'TREASURY'
                            },
                            999 : {
                                id: 999,
                                name: 'OTHERS'
                            }
                        }
            },
            'BEAUTY & WELLNESS / HEALTH & FITNESS' : {
                    id: 4,
                    name: 'BEAUTY & WELLNESS / HEALTH & FITNESS',
                    occupation: {
                            37 : {
                                id: 37,
                                name: 'ATHLETICS / FITNESS / SPORTS & RECREATION'
                            },
                            38 : {
                                id: 38,
                                name: 'BEAUTICIAN'
                            },
                            39 : {
                                id: 39,
                                name: 'NUTRITIONIST'
                            },
                            40 : {
                                id: 40,
                                name: 'THERAPIST'
                            },
                            999 : {
                                id: 999,
                                name: 'OTHERS'
                            }
                        }
            },
            'BUILDING / CONSTRUCTION' : {
                    id: 5,
                    name: 'BUILDING / CONSTRUCTION',
                    occupation: {
                            41 : {
                                id: 41,
                                name: 'ARCHITECTURAL SERVICES'
                            },
                            42 : {
                                id: 42,
                                name: 'BUILDING / CONSTRUCTION / QS'
                            },
                            43 : {
                                id: 43,
                                name: 'CIVIL / STRUCTURAL'
                            }
                        }
            },
            'DESIGN' : {
                    id: 6,
                    name: 'DESIGN',
                    occupation: {
                            44 : {
                                id: 44,
                                name: 'FASHION'
                            },
                            45 : {
                                id: 45,
                                name: 'GRAPHICS'
                            },
                            46 : {
                                id: 46,
                                name: 'INDUSTRIAL / PRODUCT'
                            },
                            47 : {
                                id: 47,
                                name: 'INTERIOR'
                            },
                            48 : {
                                id: 48,
                                name: 'MULTI-MEDIA'
                            },
                            49 : {
                                id: 49,
                                name: 'VISUAL MERCHANDISING'
                            },
                            50 : {
                                id: 50,
                                name: 'WEB DESIGN'
                            },
                            999 : {
                                id: 999,
                                name: 'OTHERS'
                            }
                        }
            },
            'EDUCATION' : {
                    id: 7,
                    name: 'EDUCATION',
                    occupation: {
                            51 : {
                                id: 51,
                                name: 'EARLY CHILDHOOD'
                            },
                            52 : {
                                id: 52,
                                name: 'LECTURER / PROFESSOR'
                            },
                            53 : {
                                id: 53,
                                name: 'LIBRARIAN'
                            },
                            54 : {
                                id: 54,
                                name: 'TEACHER'
                            },
                            55 : {
                                id: 55,
                                name: 'TUTOR / INSTRUCTOR'
                            },
                            999 : {
                                id: 999,
                                name: 'OTHERS'
                            }
                        }
            },
            'ENGINEERING' : {
                    id: 8,
                    name: 'ENGINEERING',
                    occupation: {
                            56 : {
                                id: 56,
                                name: 'CHEMICAL'
                            },
                            57 : {
                                id: 57,
                                name: 'DRAFTER / DRAFTSMAN'
                            },
                            58 : {
                                id: 58,
                                name: 'ELECTRICAL / ELECTRONICS'
                            },
                            59 : {
                                id: 59,
                                name: 'ENERGY / NATURAL RESOURCES'
                            },
                            60 : {
                                id: 60,
                                name: 'ENGINEERING PROJECT MANAGEMENT'
                            },
                            61 : {
                                id: 61,
                                name: 'HEALTH / SAFETY / ENVIRONMENTAL'
                            }
                        }
            },
            'INDUSTRIAL' : {
                    id: 9,
                    name: 'INDUSTRIAL',
                    occupation: {
                            62 : {
                                id: 62,
                                name: 'MANUFACTURING & PRODUCTION'
                            },
                            63 : {
                                id: 63,
                                name: 'MARINE / OIL & GAS'
                            },
                            64 : {
                                id: 64,
                                name: 'MECHANICAL'
                            },
                            65 : {
                                id: 65,
                                name: 'TELECOMMUNICATION / WIRELESS / RADIO'
                            },
                            999 : {
                                id: 999,
                                name: 'OTHERS'
                            }
                        }
            },
            'HOSPITALITY / F & B' : {
                    id: 10,
                    name: 'HOSPITALITY / F & B',
                    occupation: {
                            66 : {
                                id: 66,
                                name: 'FOOD & BEVERAGE'
                            },
                            67 : {
                                id: 67,
                                name: 'HOSPITALITY / HOTEL SERVICES'
                            },
                            68 : {
                                id: 68,
                                name: 'INTEGRATED RESORT / CASINO'
                            },
                            69 : {
                                id: 69,
                                name: 'MANAGEMENT'
                            },
                            70 : {
                                id: 70,
                                name: 'OPERATION'
                            },
                            71 : {
                                id: 71,
                                name: 'TOURISM / TRAVEL AGENCY'
                            },
                            999 : {
                                id: 999,
                                name: 'OTHERS'
                            }
                        }
            },
            'INFORMATION TECHNOLOGY (IT)' : {
                    id: 11,
                    name: 'INFORMATION TECHNOLOGY (IT)',
                    occupation: {
                            72 : {
                                id: 72,
                                name: 'APPLICATION SPECIALIST'
                            },
                            73 : {
                                id: 73,
                                name: 'DBA'
                            },
                            74 : {
                                id: 74,
                                name: 'HARDWARE'
                            },
                            75 : {
                                id: 75,
                                name: 'IT - WEBMASTER / SEO'
                            },
                            76 : {
                                id: 76,
                                name: 'IT AUDITING'
                            },
                            77 : {
                                id: 77,
                                name: 'IT MANAGEMENT'
                            },
                            78 : {
                                id: 78,
                                name: 'IT PROJECT MANAGEMENT / TEAM LEAD'
                            }
                        }
            },
            'MOBILE / WIRELESS COMMUNICATIONS' : {
                    id: 12,
                    name: 'MOBILE / WIRELESS COMMUNICATIONS',
                    occupation: {
                            79 : {
                                id: 79,
                                name: 'NETWORK & SYSTEM'
                            },
                            80 : {
                                id: 80,
                                name: 'PRODUCT MANAGEMENT  / BUSINESS ANALYST'
                            },
                            81 : {
                                id: 81,
                                name: 'SAP / ORACLE'
                            },
                            82 : {
                                id: 82,
                                name: 'SECURITY'
                            },
                            83 : {
                                id: 83,
                                name: 'SOFTWARE DEVELOPMENT / PROGRAMMING'
                            }
                        }
            },
            'SUPPORT' : {
                    id: 13,
                    name: 'SUPPORT',
                    occupation: {
                            84 : {
                                id: 84,
                                name: 'TECHNICAL / FUNCTIONAL CONSULTING'
                            },
                            85 : {
                                id: 85,
                                name: 'TECHNICAL WRITING'
                            },
                            86 : {
                                id: 86,
                                name: 'TESTING / QA'
                            },
                            999 : {
                                id: 999,
                                name: 'OTHERS'
                            }
                        }
            },
            'INSURANCE' : {
                    id: 14,
                    name: 'INSURANCE',
                    occupation: {
                            87 : {
                                id: 87,
                                name: 'ACTUARIAL'
                            },
                            88 : {
                                id: 88,
                                name: 'CLAIMS OFFICER'
                            },
                            89 : {
                                id: 89,
                                name: 'INSURANCE AGENT / BROKER'
                            },
                            90 : {
                                id: 90,
                                name: 'UNDERWRITER'
                            },
                            999 : {
                                id: 999,
                                name: 'OTHERS'
                            }
                        }
            },
            'MANAGEMENT' : {
                    id: 15,
                    name: 'MANAGEMENT',
                    occupation: {
                            91 : {
                                id: 91,
                                name: 'TOP EXECUTIVES (CEO, CFO, CTO, GM, MD ETC.)'
                            }
                        }
            },
            'MANUFACTURING' : {
                    id: 16,
                    name: 'MANUFACTURING',
                    occupation: {
                            92 : {
                                id: 92,
                                name: 'GARMENT/ TEXTILE'
                            },
                            93 : {
                                id: 93,
                                name: 'GEMS & JEWELRY'
                            },
                            94 : {
                                id: 94,
                                name: 'GENERAL / PRODUCTION WORKERS'
                            },
                            95 : {
                                id: 95,
                                name: 'MANUFACTURING MANAGEMENT'
                            },
                            96 : {
                                id: 96,
                                name: 'PRINTING / PUBLISHING'
                            },
                            97 : {
                                id: 97,
                                name: 'PRODUCT DEVELOPMENT / MANAGEMENT'
                            },
                            98 : {
                                id: 98,
                                name: 'PRODUCTION PLANNING / CONTROL'
                            },
                            99 : {
                                id: 99,
                                name: 'QUALITY ASSURANCE, CONTROL & TESTING / ISO'
                            },
                            999 : {
                                id: 999,
                                name: 'OTHERS'
                            }
                        }
            },
            'MARKETING / PUBLIC RELATIONS' : {
                    id: 17,
                    name: 'MARKETING / PUBLIC RELATIONS',
                    occupation: {
                            100 : {
                                id: 100,
                                name: 'BRAND / PRODUCT MANAGEMENT'
                            },
                            101 : {
                                id: 101,
                                name: 'COPY-WRITING'
                            },
                            102 : {
                                id: 102,
                                name: 'DIRECT MARKETING'
                            },
                            103 : {
                                id: 103,
                                name: 'EVENT MANAGEMENT'
                            },
                            104 : {
                                id: 104,
                                name: 'MANAGEMENT'
                            },
                            105 : {
                                id: 105,
                                name: 'MARKETING COMMUNICATION'
                            },
                            106 : {
                                id: 106,
                                name: 'MARKETING GENERAL'
                            },
                            107 : {
                                id: 107,
                                name: 'PR GENERAL'
                            },
                            108 : {
                                id: 108,
                                name: 'RESEARCH / SURVEY SERVICES'
                            },
                            999 : {
                                id: 999,
                                name: 'OTHERS'
                            }
                        }
            },
            'MEDIA & ADVERTISING' : {
                    id: 18,
                    name: 'MEDIA & ADVERTISING',
                    occupation: {
                            109 : {
                                id: 109,
                                name: 'ACCOUNT SERVICING'
                            },
                            110 : {
                                id: 110,
                                name: 'CREATIVE / DESIGN'
                            },
                            111 : {
                                id: 111,
                                name: 'EDITORIAL / JOURNALISM'
                            },
                            112 : {
                                id: 112,
                                name: 'MEDIA BUYING'
                            },
                            113 : {
                                id: 113,
                                name: 'PHOTOGRAPHY / VIDEO'
                            },
                            114 : {
                                id: 114,
                                name: 'PRINT MEDIA'
                            },
                            115 : {
                                id: 115,
                                name: 'PRODUCTION'
                            },
                            116 : {
                                id: 116,
                                name: 'STRATEGIC PLANNING'
                            },
                            117 : {
                                id: 117,
                                name: 'TV BROADCASTING'
                            },
                            999 : {
                                id: 999,
                                name: 'OTHERS'
                            }
                        }
            },
            'MEDICAL SERVICES' : {
                    id: 19,
                    name: 'MEDICAL SERVICES',
                    occupation: {
                            118 : {
                                id: 118,
                                name: 'DOCTOR / PRACTITIONER / SURGEON'
                            },
                            119 : {
                                id: 119,
                                name: 'MEDICAL SERVICES TECHNICIAN'
                            },
                            120 : {
                                id: 120,
                                name: 'NURSING'
                            },
                            121 : {
                                id: 121,
                                name: 'PHARMACEUTICAL'
                            },
                            122 : {
                                id: 122,
                                name: 'SPECIALIST'
                            },
                            123 : {
                                id: 123,
                                name: 'THERAPIST'
                            },
                            124 : {
                                id: 124,
                                name: 'VETERINARIAN'
                            },
                            999 : {
                                id: 999,
                                name: 'OTHERS'
                            }
                        }
            },
            'MERCHANDISING & PURCHASING' : {
                    id: 20,
                    name: 'MERCHANDISING & PURCHASING',
                    occupation: {
                            125 : {
                                id: 125,
                                name: 'APPARELS'
                            },
                            126 : {
                                id: 126,
                                name: 'HOUSEHOLD'
                            },
                            127 : {
                                id: 127,
                                name: 'INDUSTRIAL'
                            },
                            128 : {
                                id: 128,
                                name: 'PROCUREMENT / PURCHASING / SOURCING'
                            }
                        }
            },
            'PROFESSIONAL SERVICES' : {
                    id: 21,
                    name: 'PROFESSIONAL SERVICES',
                    occupation: {
                            129 : {
                                id: 129,
                                name: 'BUSINESS ANALYSIS / DATA ANALYSIS'
                            },
                            130 : {
                                id: 130,
                                name: 'BUSINESS CONSULTANCY'
                            },
                            131 : {
                                id: 131,
                                name: 'COMPANY SECRETARY'
                            },
                            132 : {
                                id: 132,
                                name: 'LEGAL & COMPLIANCE'
                            },
                            133 : {
                                id: 133,
                                name: 'TRANSLATION / INTERPRETATION'
                            }
                        }
            },
            'PROPERTY' : {
                    id: 22,
                    name: 'PROPERTY',
                    occupation: {
                            134 : {
                                id: 134,
                                name: 'PROPERTY CONSULTANCY'
                            },
                            135 : {
                                id: 135,
                                name: 'PROPERTY MANAGEMENT'
                            },
                            999 : {
                                id: 999,
                                name: 'OTHERS'
                            }
                        }
            },
            'PUBLIC SECTOR / CIVIL SERVICE' : {
                    id: 23,
                    name: 'PUBLIC SECTOR / CIVIL SERVICE',
                    occupation: {
                            136 : {
                                id: 136,
                                name: 'CIVIL SERVICE / PUBLIC SECTOR JOBS'
                            },
                            137 : {
                                id: 137,
                                name: 'COUNSELING'
                            },
                            138 : {
                                id: 138,
                                name: 'FOREIGN AFFAIRS'
                            },
                            139 : {
                                id: 139,
                                name: 'MILITARY / DEFENCE'
                            },
                            140 : {
                                id: 140,
                                name: 'SOCIAL SERVICES - COMMUNITY / NON-PROFIT ORGANIZATION'
                            },
                            141 : {
                                id: 141,
                                name: 'UTILITIES'
                            },
                            142 : {
                                id: 142,
                                name: 'SALES / CUSTOMER SERVICE / BUSINESS DEVELOPMENT'
                            }
                        }
            },
            'SALES / CUSTOMER SERVICE / BUSINESS DEVELOPMENT' : {
                    id: 24,
                    name: 'SALES / CUSTOMER SERVICE / BUSINESS DEVELOPMENT',
                    occupation: {
                            143 : {
                                id: 143,
                                name: 'ACCOUNT SERVICING'
                            },
                            144 : {
                                id: 144,
                                name: 'BUSINESS DEVELOPMENT'
                            },
                            145 : {
                                id: 145,
                                name: 'CALL CENTRE'
                            },
                            146 : {
                                id: 146,
                                name: 'CHANNEL / DISTRIBUTION'
                            },
                            147 : {
                                id: 147,
                                name: 'CUSTOMER SERVICE - OFFICER / EXECUTIVE'
                            },
                            148 : {
                                id: 148,
                                name: 'CUSTOMER SERVICE - SUPERVISOR / MANAGER'
                            },
                            149 : {
                                id: 149,
                                name: 'DIRECT SALES'
                            },
                            150 : {
                                id: 150,
                                name: 'RETAIL SALES'
                            },
                            151 : {
                                id: 151,
                                name: 'SALES - REAL ESTATE'
                            },
                            152 : {
                                id: 152,
                                name: 'SALES - SALES MANAGEMENT'
                            },
                            153 : {
                                id: 153,
                                name: 'TECHNICAL SALES / SALES ENGINEER'
                            },
                            154 : {
                                id: 154,
                                name: 'TELE-SALES (TELEMARKETING)'
                            },
                            155 : {
                                id: 155,
                                name: 'WHOLESALES'
                            },
                            999 : {
                                id: 999,
                                name: 'OTHERS'
                            }
                        }
            },
            'SCIENCES / LABORATORY / R&D' : {
                    id: 25,
                    name: 'SCIENCES / LABORATORY / R&D',
                    occupation: {
                            156 : {
                                id: 156,
                                name: 'BIOTECHNOLOGY'
                            },
                            157 : {
                                id: 157,
                                name: 'ENERGY / NATURAL RESOURCES / OIL & GAS'
                            },
                            158 : {
                                id: 158,
                                name: 'ENVIRONMENTAL SCIENCE / WASTE MANAGEMENT'
                            },
                            159 : {
                                id: 159,
                                name: 'FOOD SCIENCE'
                            },
                            160 : {
                                id: 160,
                                name: 'LABORATORY'
                            },
                            161 : {
                                id: 161,
                                name: 'LIFE SCIENCE'
                            },
                            162 : {
                                id: 162,
                                name: 'RESEARCH & DEVELOPMENT (R&D)'
                            },
                            163 : {
                                id: 163,
                                name: 'TELECOMMUNICATIONS'
                            },
                            164 : {
                                id: 164,
                                name: 'NETWORK ADMINISTRATION'
                            },
                            165 : {
                                id: 165,
                                name: 'RF - PLANNING / INSTALLATION / ADMINISTRATION'
                            },
                            166 : {
                                id: 166,
                                name: 'SYSTEM ADMINISTRATION'
                            },
                            167 : {
                                id: 167,
                                name: 'SYSTEM ENGINEERING'
                            },
                            168 : {
                                id: 168,
                                name: 'SYSTEMS SECURITY'
                            },
                            169 : {
                                id: 169,
                                name: 'TELECOMMUNICATIONS TECHNICAL SUPPORT'
                            },
                            999 : {
                                id: 999,
                                name: 'OTHERS'
                            }
                        }
            },
            'TRANSPORTATION / LOGISTICS' : {
                    id: 26,
                    name: 'TRANSPORTATION / LOGISTICS',
                    occupation: {
                            170 : {
                                id: 170,
                                name: 'AEROSPACE'
                            },
                            171 : {
                                id: 171,
                                name: 'AUTOMOTIVE'
                            },
                            172 : {
                                id: 172,
                                name: 'AVIATION SERVICES'
                            },
                            173 : {
                                id: 173,
                                name: 'DOCUMENTARY CREDIT / BILLS PROCESSING'
                            },
                            174 : {
                                id: 174,
                                name: 'EXPORT IMPORT'
                            },
                            175 : {
                                id: 175,
                                name: 'FREIGHT FORWARDING'
                            },
                            176 : {
                                id: 176,
                                name: 'FULFILLMENT'
                            },
                            177 : {
                                id: 177,
                                name: 'INVENTORY / WAREHOUSING'
                            },
                            178 : {
                                id: 178,
                                name: 'MARITIME - GENERAL'
                            },
                            179 : {
                                id: 179,
                                name: 'PRIVATE TRANSPORTATION'
                            },
                            180 : {
                                id: 180,
                                name: 'PUBLIC TRANSPORTATION'
                            },
                            181 : {
                                id: 181,
                                name: 'SHIPPING'
                            },
                            182 : {
                                id: 182,
                                name: 'SUPPLY CHAIN'
                            },
                            999 : {
                                id: 999,
                                name: 'OTHERS'
                            }
                        }
            },
            'AGRICULTURE / FORESTRY / FISHING' : {
                    id: 27,
                    name: 'AGRICULTURE / FORESTRY / FISHING',
                    occupation: {
                            183 : {
                                id: 183,
                                name: 'ENTERTAINMENT - ARTISTS / SINGERS / MUSICIANS'
                            },
                            184 : {
                                id: 184,
                                name: 'JUNIOR EXECUTIVE'
                            },
                            185 : {
                                id: 185,
                                name: 'MINING / GEOLOGIST'
                            },
                            186 : {
                                id: 186,
                                name: 'SECURITY / SAFETY CONTROL'
                            },
                            187 : {
                                id: 187,
                                name: 'SKILL WORKER'
                            },
                            188 : {
                                id: 188,
                                name: 'STUDENT / FRESH GRADUATE / NO EXPERIENCE'
                            },
                            189 : {
                                id: 189,
                                name: 'TECHNICIAN JOBS'
                            },
                            190 : {
                                id: 190,
                                name: 'TRADING'
                            },
                            999 : {
                                id: 999,
                                name: 'OTHERS'
                            }
                        }
            },
            'OTHERS': {
                id: 999,
                name: "OTHERS",
                occupation: {
                    9999: {
                        id: 9999,
                        name: "OTHERS"
                    }
                }
            }
        };

        self.identity_types = self.SSConfigService.getIdentityTypes();

        self.individual_titles = [
            {
                id: 1,
                name: 'MR'
            },
            {
                id: 2,
                name: 'MRS'
            },
            {
                id: 3,
                name: 'MISS'
            },
            {
                id: 4,
                name: 'DR'
            }
        ];

        self.broker_roles = [
            {
                id: 1,
                name: 'BUYER AGENT'
            },
            {
                id: 2,
                name: 'CO-BROKER'
            },
            {
                id: 3,
                name: 'TAGGER'
            },
            {
                id: 4,
                name: 'TEAM IC'
            },
            {
                id: 5,
                name: 'SALES CONSULTANT'
            }
        ];

        self.broker_roles_without_tagger = [
            {
                id: 1,
                name: 'BUYER AGENT'
            },
            {
                id: 2,
                name: 'CO-BROKER'
            }
        ];

        self.broker_roles_only_tagger = [
            {
                id: 1,
                name: 'BUYER AGENT'
            },
            {
                id: 3,
                name: 'TAGGER'
            }
        ];

        self.banks = angular.copy(self.SSConfigService.getBanks());

        self.formats = [
            {
                id: 1,
                name: 'PDF'
            },
            {
                id: 2,
                name: 'Image'
            }
        ];

        self.EOI_STATUS_DRAFT = 10;
        self.EOI_STATUS_TRANSFERRED = 11;
        self.EOI_STATUS_SUBMITTED = 20;
        self.EOI_STATUS_VERIFIED = 30;
        self.EOI_STATUS_WITHDRAWN = 40;
        self.EOI_STATUS_REJECTED = 50;
        self.EOI_STATUS_REGISTERED = 60;
        self.EOI_STATUS_SUCCESSFUL = 70;
        self.EOI_STATUS_UNSUCCESSFUL = 80;
        self.EOI_STATUS_COMPLETED = 90;

        self.eoi_statuses = [
            {
                id: self.EOI_STATUS_DRAFT,
                name: "DRAFT"
            },
            {
                id: self.EOI_STATUS_TRANSFERRED,
                name: "TRANSFERRED"
            },
            {
                id: self.EOI_STATUS_SUBMITTED,
                name: "SUBMITTED"
            },
            {
                id: self.EOI_STATUS_VERIFIED,
                name: "VERIFIED"
            },
            {
                id: self.EOI_STATUS_WITHDRAWN,
                name: "WITHDRAWN"
            },
            {
                id: self.EOI_STATUS_REJECTED,
                name: "REJECTED"
            },
            {
                id: self.EOI_STATUS_REGISTERED,
                name: "REGISTERED"
            },
            {
                id: self.EOI_STATUS_SUCCESSFUL,
                name: "SUCCESSFUL"
            },
            {
                id: self.EOI_STATUS_UNSUCCESSFUL,
                name: "UNSUCCESSFUL"
            },
            {
                id: self.EOI_STATUS_COMPLETED,
                name: "COMPLETED"
            },
        ];

        self.family_relationships = [
            {
                id: 1,
                name: 'FATHER'
            },
            {
                id: 2,
                name: 'MOTHER'
            },
            {
                id: 3,
                name: 'HUSBAND'
            },
            {
                id: 4,
                name: 'WIFE'
            },
            {
                id: 5,
                name: 'SON'
            },
            {
                id: 6,
                name: 'DAUGHTER'
            },
            {
                id: 7,
                name: 'BROTHER'
            },
            {
                id: 8,
                name: 'SISTER'
            },
            {
                id: 9,
                name: 'GRANDFATHER'
            },
            {
                id: 10,
                name: 'GRANDMOTHER'
            },
            {
                id: 99,
                name: 'OTHERS'
            }
        ];

        self.propertyDetail = {};

        self.eoi_payment_types = [
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_NO_CHEQUE,
                name: 'NO CHEQUE'
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_CHEQUE,
                name: 'CHEQUE'
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_CASHIERS_ORDER,
                name: 'CASHIERS\' ORDER'
            }
        ];

        self.booking_payment_types = [
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_CHEQUE,
                name: 'CHEQUE'
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_CASHIERS_ORDER,
                name: 'CASHIERS\' ORDER'
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_FAST_CASH,
                name: 'FAST CASH'
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_TELEGRAPHIC_TRANSFER,
                name: 'TELEGRAPHIC TRANSFER'
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_GIRO,
                name: 'GIRO'
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_DEBIT_CARD,
                name: 'DEBIT CARD'
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_CREDIT_CARD,
                name: 'CREDIT CARD'
            },
            {   id: SHOWSUITE.INSTRUMENT_TYPE_BANK_TRANSFER,
                name: "BANK TRANSFER"
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_CASH_IN,
                name: 'CASH-IN'
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_CASH,
                name: 'CASH'
            },
            {   id: SHOWSUITE.INSTRUMENT_TYPE_PAYNOW_OTHERS,
                name: "PAYNOW OTHERS"
            }
        ];

        self.payment_types = [
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_CHEQUE,
                name: 'CHEQUE'
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_CASHIERS_ORDER,
                name: 'CASHIERS\' ORDER'
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_FAST_CASH,
                name: 'FAST CASH'
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_TELEGRAPHIC_TRANSFER,
                name: 'TELEGRAPHIC TRANSFER'
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_GIRO,
                name: 'GIRO'
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_DEBIT_CARD,
                name: 'DEBIT CARD'
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_CREDIT_CARD,
                name: 'CREDIT CARD'
            },
            {   id: SHOWSUITE.INSTRUMENT_TYPE_BANK_TRANSFER,
                name: "BANK TRANSFER"
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_CASH_IN,
                name: 'CASH-IN'
            },
            {
                id: SHOWSUITE.INSTRUMENT_TYPE_CASH,
                name: 'CASH'
            },
            {   id: SHOWSUITE.INSTRUMENT_TYPE_PAYNOW_OTHERS,
                name: "PAYNOW OTHERS"
            }
        ];

        self.credit_card_companies = [
            {
                id: 'Visa',
                name: 'Visa'
            },
            {
                id: 'Mastercard',
                name: 'Mastercard'
            },
            {
                id: 'Other',
                name: 'Other'
            }
        ];

        self.pdi_document_kinds = [
            {
                id: SHOWSUITE.PROPERTY_DOCUMENT_KIND_BUYER_PARTICULARS,
                name: 'PURCHASER PARTICULARS'
            },
            {
                id: SHOWSUITE.PROPERTY_DOCUMENT_KIND_DECLARATION_FORM,
                name: 'PURCHASER DECLARATION'
            },
            {
                id: SHOWSUITE.PROPERTY_DOCUMENT_KIND_COVER_LETTER_FORM,
                name: 'SIDE LETTER'
            },
            {
                id: SHOWSUITE.PROPERTY_DOCUMENT_KIND_ANNEXES,
                name: 'ANNEXES'
            },
            {
                id: SHOWSUITE.PROPERTY_DOCUMENT_KIND_ANNEX_A,
                name: 'ANNEX A'
            },
            {
                id: SHOWSUITE.PROPERTY_DOCUMENT_KIND_ANNEX_B3,
                name: 'ANNEX B3'
            }
        ];

        self.beneficiary_identity_type = [
            {
                id: SHOWSUITE.BENEFICIARY_IDENTITY_NRIC,
                name: 'NRIC'
            },
            {
                id: SHOWSUITE.BENEFICIARY_IDENTITY_PASSPORT,
                name: 'Passport'
            },
            {
                id: SHOWSUITE.BENEFICIARY_IDENTITY_BIRTH_CERTIFICATE,
                name: 'Birth Certificate'
            }
        ];

        self.BUYER_TYPES = [
            { id: SHOWSUITE.BUYER_TYPE_INDIVIDUAL, name: 'Individual' },
            { id: SHOWSUITE.BUYER_TYPE_COMPANY, name: 'Company' }
        ];

        self.init();
    }

    init() {
        const self = this;

        self.existingEoiObject = false;
        self.callback = null;
        self.verified = null;
        self.params = {};
        self.property = null;
        self.eoi = {};
        self.eois = [];
        self.searched = false;
        self.new_buyer = false;
        self.relationships = [];
        self.nonBuyerPayees = [];

        self.resetBuyer();

        self.addedUnits = [
            {id: 1, choices: []}
        ];

        self.unitSelection = [];
        self.unitSelectionError = false;
        self.buyerParticularError = false;
        self.coBrokeError = false;
        self.hasCoBroke = null;
        self.hideFooterButtons = false;
        self.selected_agent = null;

        self.brokers = [];
        self.brokers_obj = [];

        self.summary_checkbox = false;
        self.confirmationAgent = null;

        //Launch Day
        self.agreeTnC = false;
    }

    resetBuyer() {
        const self = this;
        self.buyers = [];
        self.searchedBuyer = null;
    }

    addBuyer(buyer, isChangeBuyerType = false) {
        const self = this;

        if(buyer.type === SHOWSUITE.BUYER_TYPE_INDIVIDUAL &&
                self.buyers && self.buyers.length && self.buyers[0].type === SHOWSUITE.BUYER_TYPE_COMPANY){
            // If signatory need to check index where to push buyer
            self.buyers.splice(self.getNextIndexForAddingSignatory(buyer.buyer_company_id), 0, buyer);
        }else {
            if(isChangeBuyerType && buyer.type === SHOWSUITE.BUYER_TYPE_COMPANY){ // If company buyer added after buyer entity type change
                self.buyers.splice(0, 0, buyer); // Add company to 0 index
                self.buyers = self.buyers.map(b => { // Add company id as buyer_company_id to individual buyers to convert them to signatories
                    if(b.type === SHOWSUITE.BUYER_TYPE_INDIVIDUAL){
                        b.buyer_company_id = buyer.id;
                    }
                    return b;
                })
            }else{
                self.buyers.push(buyer);
            }
        }
    }

    removeBuyer(buyer) {
        const self = this;

        if(buyer.type === SHOWSUITE.BUYER_TYPE_COMPANY){
            // If company being removed, move its signatories under 1st buyer and update buyer_company_id
            let company_signatories = self.getSignatoriesAgainstCompanyId(buyer.id);
            if(company_signatories && company_signatories.length){
                company_signatories.forEach(s => {
                    let current_index = self.buyers.findIndex(b => b.id === s.id);
                    let new_index = self.getNextIndexForAddingSignatory(self.buyers[0].id);

                    s.buyer_company_id = self.buyers[0].id; // Change signatory's buyer_company_id to 1st buyer company id
                    self.array_move(self.buyers, current_index, new_index); // Update signatory index
                })
            }
        }

        self.buyers = self.buyers.filter(function(item) {
          return item.id !== buyer.id;
        });
    }

    /**
     * Function array_move
     * Params: arr(original array), old_index(current index of item to move), new_index(index where the to move item)
     * This function mutates the original array to re-position array items so return statement is redundant
     *
     * return mutated array
     */
    array_move(arr, old_index, new_index) {
        /** if (new_index >= arr.length) { // If item moved to an index greater than array length
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        } **/
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

    addNonBuyerPayee(nonBuyerPayee) {
        const self = this;

        if(nonBuyerPayee.type === SHOWSUITE.BUYER_TYPE_INDIVIDUAL && nonBuyerPayee.buyer_company_id){
            // If signatory need to check index where to push buyer
            self.nonBuyerPayees.splice(self.getNextIndexForAddingNonBuyerPayeeSignatory(nonBuyerPayee.buyer_company_id), 0, nonBuyerPayee);
        }else {
            self.nonBuyerPayees.push(nonBuyerPayee);
        }
    }

    removeNonBuyerPayee(nonBuyerPayee) {
        const self = this;

        if(nonBuyerPayee.type === SHOWSUITE.BUYER_TYPE_COMPANY){
            // If company being removed, remove its signatories as well
            let company_signatories = self.getSignatoriesAgainstCompanyId(nonBuyerPayee.id, self.nonBuyerPayees);
            if(company_signatories && company_signatories.length){
                company_signatories.forEach(s => {
                    self.nonBuyerPayees = self.nonBuyerPayees.filter(function(item) {
                        return item.id !== s.id;
                    });
                })
            }
        }

        self.nonBuyerPayees = self.nonBuyerPayees.filter(function(item) {
            return item.id !== nonBuyerPayee.id;
        });
    }

    addEntity(entity, entity_type) {
        const self = this;

        if(!self[entity_type]) self[entity_type] = [];

        self[entity_type].push(entity);
    }

    updateEntity(entity, entity_type) {
        const self = this;

        if(!self[entity_type]) self[entity_type] = [];

        $.each(self[entity_type], function(i, obj){
            if(entity.id == obj.id){
                obj.company = entity.company;
                obj.individual = entity.individual;
                obj.email_contact = entity.email_contact;
                obj.phone1_contact = entity.phone1_contact;
                obj.primary_address = entity.primary_address;
                obj.mailing_address = entity.mailing_address;
                obj.buyer_company_id = entity.buyer_company_id;
            }
        });
    }

    removeWitness(entity) {
        const self = this;

        self.witnesses = self.witnesses.filter(function(item) {
          return item.id !== entity.id;
        });
    }

    removeEntity(entity, entity_type = 'witnesses') {
        const self = this;

        if(!self[entity_type] || !self[entity_type].length) return;

        self[entity_type] = self[entity_type].filter(function(item) {
          return item.id !== entity.id;
        });
    }

    addOccupier(occupier) {
        const self = this;

        self.occupiers.push(occupier);
    }

    removeOccupier(occupier) {
        const self = this;

        self.occupiers = self.occupiers.filter(function(item) {
          return item.id !== occupier.id;
        });
    }

    isValidUnitNum(unit_no){
        const self = this;

        let pattern = /^#?\d{2,4}-\d{2,4}[A-Za-z]?(\/\d{2,4}[A-Za-z]?)?$/;

        return unit_no.match(pattern);
    }

    updateUnitSelection() {
        const self = this;

        self.unitSelection = [];

        self.addedUnits.forEach(function(unit, unit_index) {
            var unit_arr = [];
            if (unit.hasOwnProperty('choices')) {
                unit.choices.forEach(function(choice, choice_index) {
                    if (choice.hasOwnProperty('unit') && choice.unit != null) {
                        unit_arr.push(choice.unit.id);
                    }
                });
            }
            self.unitSelection.push(unit_arr);
        });

        self.unitSelectionError = self.unitSelection.length > 0 ? false: true;
    }

    formatSGAddressUnitFloor(unit, floor) {
        const self = this;

        if (unit.length === 1) {
            unit = "0" + unit;
        }

        if (floor.length === 1) {
            floor = "0" + floor;
        }

        return "#" + floor + "-" + unit;
    }

    getDiscountInfo(sale_discounts, property_discounts, unit_price){
        const self = this;
        let formatted_discounts = [];
        let final_discounts = [];

        let listPrice = unit_price;
        let salePrice = unit_price;

        // Filter discounts for current unit
        $.each(sale_discounts, function (index, discount) {

            let _discount = property_discounts.find(x => x.id == discount.property_discount_id);

            // Copy fields (which are not available) from sale discount object
            _discount.remarks = discount.remarks;
            _discount.type_name = self.SSConfigService.getDiscountTypeName(_discount.type);
            _discount.property_discount_id = discount.property_discount_id;
            _discount.discount_value = self.getDiscountedAmount(_discount,listPrice);
            _discount.discounted_amount = discount.discounted_amount;

            final_discounts.push(_discount);

            if(self.isInstantDiscountType(_discount.type)){
                formatted_discounts.push(_discount);
            }

        });

        // Sort by order field
        formatted_discounts = _.sortBy(formatted_discounts, 'order');

        // Apply Standard Discount, and get Net Price
        let netPrice = listPrice; // At this stage, list price is the net price

        let standard_discount = formatted_discounts.find(x => x.type == SHOWSUITE.DISCOUNT_TYPE_STANDARD);

        if(standard_discount){
            netPrice -= self.getDiscountedAmount(standard_discount, listPrice);
            // Remove STANDARD DISCOUNT
            formatted_discounts = $.grep(formatted_discounts, function(obj){
                return obj.type != SHOWSUITE.DISCOUNT_TYPE_STANDARD;
            });
        }

        salePrice = netPrice; // At this stage, net price is the selling price

        // Filter and apply discounts with calculate_on_list_price = 1
        let discounts1 = [];
        $.each(formatted_discounts, function(index, discount){
            if(discount.calculate_on_list_price){
                discounts1.push(discount);
            }
        });

        $.each(discounts1, function(index, discount){
            salePrice -= self.getDiscountedAmount(discount, listPrice);
        });

        // Filter and apply discounts with calculate_on_list_price = 0
        let discounts2 = [];
        $.each(formatted_discounts, function(index, discount){
            if(!discount.calculate_on_list_price){
                discounts2.push(discount);
            }
        });

        // Apply on netPrice
        $.each(discounts2, function(index, discount){
            salePrice -= self.getDiscountedAmount(discount, netPrice);
        });

        return { final_sale_price: salePrice, discounts: final_discounts };
    }

    getDiscountedAmount(discount, price){
        const self = this;

        if (discount.amount_type == SHOWSUITE.VALUE_TYPE_PERCENTAGE) {
            if(discount.rounding_method && discount.round_to){
                if(discount.rounding_method == SHOWSUITE.ROUNDING_METHOD_STANDARD){
                    discount.discount_value = Math.round(price * (discount.amount_value / (100*discount.round_to))) * discount.round_to;
                } else if(discount.rounding_method == SHOWSUITE.ROUNDING_METHOD_FLOOR){
                    discount.discount_value = Math.floor(price * (discount.amount_value / (100*discount.round_to))) * discount.round_to;
                } else if(discount.rounding_method == SHOWSUITE.ROUNDING_METHOD_CEIL){
                    discount.discount_value = Math.ceil(price * (discount.amount_value / (100*discount.round_to))) * discount.round_to;
                }
            } else {
                discount.discount_value = Math.round(price * (discount.amount_value / 100));
            }

        } else if (discount.amount_type == SHOWSUITE.VALUE_TYPE_AMOUNT) {
            discount.discount_value = discount.amount_value;
        }

        return discount.discount_value;
    }

    getDiscountRoundedLabel(discount){
        const self = this;

        let label = '';

        let ROUND_TO_VALUES = {1: 'One', 10: 'Ten', 100: 'Hundred', 1000: 'Thousand'};

        if (discount.amount_type == SHOWSUITE.VALUE_TYPE_PERCENTAGE) {
            if(discount.rounding_method && discount.round_to){
                if(discount.rounding_method == SHOWSUITE.ROUNDING_METHOD_STANDARD){
                    label += (discount.amount_value +'% - Rounding to '+ ROUND_TO_VALUES[discount.round_to]);
                } else if(discount.rounding_method == SHOWSUITE.ROUNDING_METHOD_FLOOR){
                    label += (discount.amount_value +'% - Rounding Down to '+ ROUND_TO_VALUES[discount.round_to]);
                } else if(discount.rounding_method == SHOWSUITE.ROUNDING_METHOD_CEIL){
                    label += (discount.amount_value +'% - Rounding Up to '+ ROUND_TO_VALUES[discount.round_to]);
                }
            } else {
                label += (discount.amount_value + '% - Rounding To One');
            }
        }

        return label;
    }

    isInstantDiscountType(type){
        const self = this;

        if(type == SHOWSUITE.DISCOUNT_TYPE_STAFF
          || type == SHOWSUITE.DISCOUNT_TYPE_STANDARD
          || type == SHOWSUITE.DISCOUNT_TYPE_PROMO_CODE_SINGLE_USE
          || type == SHOWSUITE.DISCOUNT_TYPE_PROMO_CODE_MULTIPLE_USE
          || type == SHOWSUITE.DISCOUNT_TYPE_PROMO_CODE_OTHER
          || type == SHOWSUITE.DISCOUNT_TYPE_PROMO_CODE_ADDITIONAL){
          return true;
        }
        else{
          return false;
        }
    }

    developerAuthorisation(callback) {
        const self = this;

        if (typeof callback === "function") {
            self.callback = callback;
        }

        self.authorisation_code = '';
        $('#developerAuthorisationModal').modal('show');
    }

    submitAuthorisation(authorisation_code){
        const self = this;

        if(!authorisation_code){
            self.authorisation_error = "Please enter authorisation code.";
            return;
        }

        if ($('#developerAuthorisationModal').is(':visible')) $('#developerAuthorisationModal').modal('hide');

        self.callback(authorisation_code);
    }

    getMainBroker(eoi_obj, BROKER_ROLE_MAIN_BROKER, BROKER_ROLE_SALES_CONSULTANT, BROKER_ROLE_TEAMLEAD) {
        const self = this;

        if(!eoi_obj){
            return eoi_obj;
        }

        if (eoi_obj.hasOwnProperty("brokers") && eoi_obj.brokers.length > 0) {
            let found_broker = {};

            found_broker = eoi_obj.brokers.find(x => x.broker_role == BROKER_ROLE_MAIN_BROKER);

            if (!found_broker) {
              found_broker = eoi_obj.brokers.find(x => x.broker_role == BROKER_ROLE_SALES_CONSULTANT);

              if (!found_broker) {
                found_broker = eoi_obj.brokers.find(x => x.broker_role == BROKER_ROLE_TEAMLEAD);
              }
            }

            if (!found_broker) {
              eoi_obj["main_broker"] = {};
              if (eoi_obj.brokers && eoi_obj.brokers.length > 0) {
                eoi_obj["main_broker"] = eoi_obj.brokers[0];
                eoi_obj["main_broker"]["name"] = eoi_obj.brokers[0].first_name + ' ' + eoi_obj.brokers[0].last_name;
                eoi_obj["broker_agency"] = eoi_obj.brokers[0].agency_name;
              } else {
                eoi_obj["main_broker"] = {};
              }
            } else {
              eoi_obj["main_broker"] = found_broker;
              eoi_obj["main_broker"]["name"] = eoi_obj.main_broker.first_name + ' ' + eoi_obj.main_broker.last_name;
              eoi_obj["broker_agency"] = eoi_obj.main_broker.agency_name;
              eoi_obj["broker_agency_short_code"] = eoi_obj.main_broker.agency_short_code;
            }
        }

        return eoi_obj;
    }

    getPaymentTypeDisplayName(payment_type) {
        switch (parseInt(payment_type)) {
            case SHOWSUITE.PAYMENT_PLAN_TYPE_NPS:
                return "NPS";
                break;

            case SHOWSUITE.PAYMENT_PLAN_TYPE_DPS:
                return "DPS";
                break;

            case SHOWSUITE.PAYMENT_PLAN_TYPE_PVT:
                return "PVT";
                break;

            default:
                return ""
                break;
        }
    }

    checkBuyerFinishedUpdatedAllFields(buyer, buyers, form_settings) {
        const self = this;

        let valid = true;
        let buyerIndex = (buyers && buyers.length)? buyers.findIndex(b => b.id === buyer.id) : 0;

        try{
        if (!buyer.type) {
            valid = false;
        } else if(form_settings) {
            if (buyer.type === SHOWSUITE.BUYER_TYPE_COMPANY) {
                if(!buyer.company){
                    valid = false;
                } else {
                    // Name of the Company
                    if (form_settings.company_name
                        && form_settings.company_name.required && !buyer.company.name) { valid = false; }

                    // Company UEN No.
                    if (form_settings.company_registration_no
                        && form_settings.company_registration_no.required && !buyer.company.reg_no) { valid = false; }

                    // Country Of Birth
                    if (form_settings.company_country_of_origin && form_settings.company_country_of_origin.required
                        && !buyer.company.nationality) { valid = false; }

                    // Company Email
                    if (form_settings.company_email && form_settings.company_email.required
                        && (!buyer.email_contact || !buyer.email_contact.value)) { valid = false; }

                    // Company Contact Number
                    if (form_settings.company_phone1 && form_settings.company_phone1.required
                        && (!buyer.phone1_contact || !buyer.phone1_contact.value)) { valid = false; }

                    // Secondary Contact Number
                    if (form_settings.company_phone2 && form_settings.company_phone2.required
                        && (!buyer.phone2_contact || !buyer.phone2_contact.value)) { valid = false; }

                    // Validate Company Address
                    if(!self.isValidAddress(buyer, buyerIndex, form_settings)){ valid = false; }
                }
            } else if (buyers[0].type === SHOWSUITE.BUYER_TYPE_COMPANY // Signatory
                    && buyer.type === SHOWSUITE.BUYER_TYPE_INDIVIDUAL) {
                if(!buyer.individual){
                    valid = false;
                } else if(form_settings) {
                    // Signatory Designation
                    if (form_settings.signatory_designation
                        && form_settings.signatory_designation.required && !buyer.individual.designation) { valid = false; }

                    // Salutation of Signatory
                    if (form_settings.signatory_title && form_settings.signatory_title.required
                        && (buyer.individual.title === null || buyer.individual.title === undefined)) { valid = false; }

                    // Full Name as in NRIC/Passport of Signatory
                    if (form_settings.signatory_legal_name
                        && form_settings.signatory_legal_name.required && !buyer.individual.legal_name) { valid = false; }

                    // First Name of Signatory
                    if (form_settings.signatory_first_name
                        && form_settings.signatory_first_name.required && !buyer.individual.first_name) { valid = false; }

                    // Last Name of Buyer(Surname)
                    if (form_settings.signatory_last_name
                        && form_settings.signatory_last_name.required && !buyer.individual.last_name) { valid = false; }

                    // Type of Industry
                    if (form_settings.signatory_industry && form_settings.signatory_industry.required
                        && (buyer.individual.industry === null || buyer.individual.industry === undefined)) { valid = false; }

                    // Occupation
                    if (form_settings.signatory_occupation && form_settings.signatory_occupation.required
                        && (buyer.individual.occupation === null || buyer.individual.occupation === undefined)) { valid = false; }

                    // Gender
                    if (form_settings.signatory_gender && form_settings.signatory_gender.required
                        && (buyer.individual.gender === null || buyer.individual.gender === undefined)) { valid = false; }

                    if (form_settings.signatory_identity_type && form_settings.signatory_identity_type.required) {
                        // Identity Type
                        if (!buyer.individual.identity_type) {
                            valid = false;
                        } else if (buyer.individual.identity_type === SHOWSUITE.IDENTITY_TYPE_FOREIGNER) {
                            //Passport No
                            if (form_settings.signatory_passport_no
                                && form_settings.signatory_passport_no.required && !buyer.individual.passport_no) { valid = false; }
                            // Passport Issued By
                            if (form_settings.signatory_passport_issued_by
                                && form_settings.signatory_passport_issued_by.required && !buyer.individual.passport_issued_by) { valid = false; }
                            // Passport Issued Date
                            if (form_settings.signatory_passport_issued_date
                                && form_settings.signatory_passport_issued_date.required && !buyer.individual.passport_issued_date) { valid = false; }
                            // Passport Expiry Date
                            if (form_settings.signatory_passport_expiry_date
                                && form_settings.signatory_passport_expiry_date.required && !buyer.individual.passport_expiry_date) { valid = false; }
                        } else if (buyer.individual.identity_type !== SHOWSUITE.IDENTITY_TYPE_FOREIGNER) {
                            // NRIC
                            if (form_settings.signatory_national_id
                                && form_settings.signatory_national_id.required && !buyer.individual.national_id) { valid = false; }
                            // Race
                            if (form_settings.signatory_race
                                && form_settings.signatory_race.required && !buyer.individual.race) { valid = false; }
                        }
                    }

                    // Nationality
                    if (form_settings.signatory_nationality && form_settings.signatory_nationality.required
                        && (buyer.individual.nationality === null || buyer.individual.nationality === undefined)) { valid = false; }

                    // Country Of Birth
                    if (form_settings.signatory_country_of_origin && form_settings.signatory_country_of_origin.required
                        && (buyer.individual.country_of_origin === null || buyer.individual.country_of_origin ===undefined)) { valid = false; }

                    // Monthly Income (S$)
                    if (form_settings.signatory_monthly_income && form_settings.signatory_monthly_income.required
                        && (buyer.individual.monthly_income === null || buyer.individual.monthly_income === undefined)) { valid = false; }

                    // Employment Status
                    if (form_settings.signatory_employment_status && form_settings.signatory_employment_status.required
                        && (buyer.individual.employment_status === null || buyer.individual.employment_status === undefined)) { valid = false; }

                    // Education Level
                    if (form_settings.signatory_education_level && form_settings.signatory_education_level.required
                        && (buyer.individual.education_level === null || buyer.individual.education_level === undefined)) { valid = false; }

                    if (form_settings.signatory_marital_status && form_settings.signatory_marital_status.required) {
                        // Marital Status
                        if (buyer.individual.marital_status === null || buyer.individual.marital_status === undefined) { valid = false;
                        } else if (buyer.individual.marital_status === 2 // Spouse National ID
                            && form_settings.signatory_spouse_national_id
                            && form_settings.signatory_spouse_national_id.required
                            && !buyer.individual.spouse_national_id) { valid = false; }
                    }

                    // Date of Birth
                    if (form_settings.signatory_dob && form_settings.signatory_dob.required) {
                        if (!buyer.individual.dob) { valid = false; }
                        else if (moment().diff(moment(buyer.individual.dob, "DD/MM/YYYY")) < 0) { valid = false; }
                    }

                    // Email
                    if (form_settings.signatory_email && form_settings.signatory_email.required
                        && (!buyer.email_contact || !buyer.email_contact.value)) { valid = false; }

                    // Mobile Number
                    if (form_settings.signatory_phone1 && form_settings.signatory_phone1.required
                        && (!buyer.phone1_contact || !buyer.phone1_contact.value)) { valid = false; }

                    // Mobile Number
                    if (form_settings.signatory_phon2 && form_settings.signatory_phon2.required
                        && (!buyer.phone2_contact || !buyer.phone2_contact.value)) { valid = false; }

                    // Residence Type
                    if (form_settings.signatory_residence_type
                        && form_settings.signatory_residence_type.required && !buyer.residence_type) {
                        valid = false;
                    }
                }
            } else if (buyer.type === SHOWSUITE.BUYER_TYPE_INDIVIDUAL) {
                if(!buyer.individual){
                    valid = false;
                } else if(form_settings) {
                    // Salutation
                    if (form_settings.title
                        && form_settings.title.required && !buyer.individual.title) { valid = false; }

                    // Full Name as in NRIC/Passport
                    if (form_settings.legal_name
                        && form_settings.legal_name.required && !buyer.individual.legal_name) { valid = false; }

                    // First Name of Buyer
                    if (form_settings.first_name
                        && form_settings.first_name.required && !buyer.individual.first_name) { valid = false; }

                    // Last Name of Buyer(Surname)
                    if (form_settings.last_name
                        && form_settings.last_name.required && !buyer.individual.last_name) { valid = false; }

                    // Type of Industry
                    if (form_settings.industry && form_settings.industry.required &&
                        (buyer.individual.industry === null || buyer.individual.industry === undefined)) { valid = false; }

                    // Occupation
                    if (form_settings.occupation && form_settings.occupation.required
                        && (buyer.individual.occupation === null || buyer.individual.occupation === undefined)) { valid = false; }

                    // Gender
                    if (form_settings.gender && form_settings.gender.required
                        && (buyer.individual.gender === null || buyer.individual.gender === undefined)) { valid = false; }

                    if (form_settings.identity_type && form_settings.identity_type.required) {
                        // Identity Type
                        if (!buyer.individual.identity_type) {
                            valid = false;
                        } else if (buyer.individual.identity_type === SHOWSUITE.IDENTITY_TYPE_FOREIGNER) {
                            //Passport No
                            if (form_settings.passport_no
                                && form_settings.passport_no.required && !buyer.individual.passport_no) { valid = false; }
                            // Foreigner National ID
                            if (form_settings.foreigner_national_id
                                && form_settings.foreigner_national_id.required && !buyer.individual.foreigner_national_id ) { valid = false; }
                            // Passport Issued By
                            if (form_settings.passport_issued_by
                                && form_settings.passport_issued_by.required && !buyer.individual.passport_issued_by) { valid = false; }
                            // Passport Issued Date
                            if (form_settings.passport_issued_date
                                && form_settings.passport_issued_date.required && !buyer.individual.passport_issued_date) { valid = false; }
                            // Passport Expiry Date
                            if (form_settings.passport_expiry_date
                                && form_settings.passport_expiry_date.required && !buyer.individual.passport_expiry_date) { valid = false; }
                        } else if (buyer.individual.identity_type !== SHOWSUITE.IDENTITY_TYPE_FOREIGNER) {
                            // NRIC
                            if (form_settings.national_id
                                && form_settings.national_id.required && !buyer.individual.national_id) { valid = false; }
                            // Race
                            if (form_settings.race
                                && form_settings.race.required && !buyer.individual.race) { valid = false; }
                        }
                    }

                    // Nationality
                    if (form_settings.nationality && form_settings.nationality.required
                        && (buyer.individual.nationality === null || buyer.individual.nationality === undefined)) { valid = false; }

                    // Country Of Birth
                    if (form_settings.country_of_origin && form_settings.country_of_origin.required
                        && (buyer.individual.country_of_origin === null || buyer.individual.country_of_origin === undefined)) { valid = false; }

                    // Monthly Income (S$)
                    if (form_settings.monthly_income && form_settings.monthly_income.required
                        && (buyer.individual.monthly_income === null || buyer.individual.monthly_income === undefined)) { valid = false; }

                    // Employment Status
                    if (form_settings.employment_status && form_settings.employment_status.required
                        && (buyer.individual.employment_status === null || buyer.individual.employment_status === undefined)) { valid = false; }

                    // Education Level
                    if (form_settings.education_level && form_settings.education_level.required
                        && (buyer.individual.education_level === null || buyer.individual.education_level === undefined)) { valid = false; }


                    if (form_settings.marital_status && form_settings.marital_status.required) {
                        // Marital Status
                        if (buyer.individual.marital_status === null || buyer.individual.marital_status === undefined) { valid = false;
                        } else if (buyer.individual.marital_status === 2 // Spouse National ID
                            && form_settings.spouse_national_id
                            && form_settings.spouse_national_id.required
                            && !buyer.individual.spouse_national_id) { valid = false; }
                    }

                    // Date of Birth
                    if (form_settings.dob && form_settings.dob.required) {
                        if (!buyer.individual.dob) { valid = false; }
                        else if (moment().diff(moment(buyer.individual.dob, "DD/MM/YYYY")) < 0) { valid = false; }
                    }

                    // Email
                    if (form_settings.email && form_settings.email.required
                        && (!buyer.email_contact || !buyer.email_contact.value)) { valid = false; }

                    // Mobile Number
                    if (form_settings.phone1 && form_settings.phone1.required
                        && (!buyer.phone1_contact || !buyer.phone1_contact.value)) { valid = false; }

                    // Mobile Number
                    if (form_settings.phon2 && form_settings.phon2.required
                        && (!buyer.phone2_contact || !buyer.phone2_contact.value)) { valid = false; }

                    // Relationship with Buyer 1
                    if (buyerIndex > 0) {
                        if (!buyer.individual.relationship) {
                            valid = false;
                        }
                    }

                    // Validate Address
                    if(!self.isValidAddress(buyer, buyerIndex, form_settings)){
                        valid = false;
                    }

                    // Residence Type
                    if (form_settings.residence_type
                        && form_settings.residence_type.required && !buyer.residence_type) {
                        valid = false;
                    }
                }
            }
        }
        }catch(e){
            console.error("Failed to check if Buyer Finished Updating All Fields",e);
        }

        return valid;
    }

    isValidAddress(buyer, buyerIndex, form_settings){
        const self = this;

        let valid = true;

        /**  Primary Address **/
        if (buyer.primary_address) {
            if (form_settings.primary_address_postcode
                    && form_settings.primary_address_postcode.required
                    && buyer.primary_address.country === self.SINGAPORE_SELECTOR_ID) {
                if (!buyer.primary_address.postcode) {
                    valid = false;
                } else if (buyer.primary_address.postcode.length !== 6) {
                    valid = false;
                }
            }

            if (form_settings.primary_address_line1
                    && form_settings.primary_address_line1.required
                    && !buyer.primary_address.line1) {
                valid = false;
            }

            if (form_settings.primary_address_line2
                    && form_settings.primary_address_line2.required
                    && !buyer.primary_address.line2) {
                valid = false;
            }

            if (form_settings.primary_address_unit_no
                    && form_settings.primary_address_unit_no.required
                    && buyer.primary_address.residence_type !== 30) {
                if (!buyer.primary_address.unit_no) {
                    valid = false;
                }
            }

            if (form_settings.primary_address_city
                    && form_settings.primary_address_city.required
                    && !buyer.primary_address.city) {
                valid = false;
            }

            if (buyer.primary_address.country !== self.SINGAPORE_SELECTOR_ID
                    && form_settings.primary_address_district
                    && form_settings.primary_address_district.required
                    && !buyer.primary_address.district) {
                valid = false;
            }
        }

        /**  Mailing Address **/
        if(buyer.mailing_address && !buyer.mailing_address.same){
            if (form_settings.primary_address_postcode
                    && form_settings.primary_address_postcode.required
                    && buyer.mailing_address.country === self.SINGAPORE_SELECTOR_ID) {
                if (!buyer.mailing_address.postcode) {
                    valid = false;
                } else if (buyer.mailing_address.postcode.length !== 6) {
                    valid = false;
                }
            }

            if (form_settings.primary_address_line1
                    && form_settings.primary_address_line1.required
                    && !buyer.mailing_address.line1) {
                valid = false;
            }

            if (form_settings.primary_address_line2
                    && form_settings.primary_address_line2.required
                    && !buyer.mailing_address.line2) {
                valid = false;
            }

            if (form_settings.primary_address_unit_no
                    && form_settings.primary_address_unit_no.required
                    && buyer.mailing_address.residence_type !== 30) {
                if (!buyer.mailing_address.unit_no) {
                    valid = false;
                }
            }

            if (form_settings.primary_address_city
                    && form_settings.primary_address_city.required
                    && !buyer.mailing_address.city) {
                valid = false;
            }

            if (buyer.mailing_address.country !== self.SINGAPORE_SELECTOR_ID
                    && form_settings.primary_address_district
                    && form_settings.primary_address_district.required
                    && !buyer.mailing_address.district) {
                valid = false;
            }
        }

        return valid;
    }

    checkAllBuyerFinishedUpdatedAllFields(){
        const self = this;

        if(self.buyers && self.buyers.length){
            self.buyers.forEach(b => {
                b.updated_success = self.checkBuyerFinishedUpdatedAllFields(b, self.buyers, self.form_settings)
            })
        }
    }

    getOtherDocumentTypeName(format_type) {
        switch (parseInt(format_type)) {
            case SHOWSUITE.PROPERTY_DOCUMENT_FORMAT_PDF:
                return "PDF";
                break;

            case SHOWSUITE.PROPERTY_DOCUMENT_FORMAT_IMG:
                return "Image";
                break;

            default:
                return ""
                break;
        }
    }

    isLaunchDay(_launchStartDate,_launchEndDate){

        let date = new Date();

        let currentDateTime = new moment();
        let launchStartDateTime = new moment(moment.utc(_launchStartDate).local().format('YYYY-MM-DD HH:mm:ss'));
        let launchEndDateTime = new moment(moment.utc(_launchEndDate).local().format('YYYY-MM-DD HH:mm:ss'));

        // If the date launch start time and less than launch end time
        if(launchStartDateTime.isBefore(currentDateTime) && launchEndDateTime.isAfter(currentDateTime)){
          return true;
        }
        
        return false;
    }

    isEoiCreatedBeforeLaunch(eoi_created_at, launch_start_date){

        if(!eoi_created_at || !launch_start_date){
            return;
        }

        let eoiDateTime = new moment(moment.utc(eoi_created_at).local().format('YYYY-MM-DD HH:mm:ss'));
        let launchStartDateTime = new moment(moment.utc(launch_start_date).local().format('YYYY-MM-DD HH:mm:ss'));

        // https://stackoverflow.com/questions/21284312/moment-js-check-if-a-date-is-today-or-in-the-future
        // today < future (31/01/2014)
        // today.diff(future) // today - future < 0
        // future.diff(today) // future - today > 0
        if(eoiDateTime.diff(launchStartDateTime) < 0){
          return true;
        }

        return false;
    }

    getIdentityLabel(identity_type, thai_project = false){
        const self = this;

        if(thai_project && identity_type.toLowerCase() != 'foreigner'){
            if(identity_type.toLowerCase() == 'singaporean'){
                identity_type = 'Thai Local';
            }
            else if(identity_type.toLowerCase() == 'singaporean_pr' || identity_type.toLowerCase() == 'singaporean pr'){
                identity_type = 'Thai Resident';
            }
        }

        return identity_type;
    }

    getFormattedAnswer(answer_text, get_last_answer = false) {
        const self = this;

        if (!answer_text) {
            console.log("Empty/null answer_text");
            return null;
        }

        if (self.SSUtilService.isJSON(answer_text)) {
            let formatted_answers = JSON.parse(answer_text);

            if (get_last_answer) {
                return typeof (formatted_answers) == "object" ? formatted_answers[formatted_answers.length - 1] : formatted_answers;
            }

            return formatted_answers;
        } else {
            // It's string, return as it is
            return answer_text;
        }
    }

    checkNonBuyerPayeeFinishedUpdatedAllFields(nonBuyerPayee, nonBuyerPayees, pp_form_required_fields_non_buyer_payee) {
        const self = this;
        
        let valid = true;
        if (!nonBuyerPayee.type) {
            console.warn("Entity type missing for 3rd Party Payor");
            return  false;
        }

        if (nonBuyerPayee.type === SHOWSUITE.BUYER_TYPE_COMPANY && pp_form_required_fields_non_buyer_payee) {
            if (!nonBuyerPayee.company){
                console.warn("Malformed 3rd Party Payor company object");
                return false;
            }

            // Name of the Company
            if (pp_form_required_fields_non_buyer_payee.company_name
                    && pp_form_required_fields_non_buyer_payee.company_name.required
                    && !nonBuyerPayee.company.name) {
                valid = false;
            }

            // Company UEN No.
            if (pp_form_required_fields_non_buyer_payee.company_registration_no
                    && pp_form_required_fields_non_buyer_payee.company_registration_no.required
                    && !nonBuyerPayee.company.reg_no) {
                valid = false;
            }

            // Country Of Birth
            if (pp_form_required_fields_non_buyer_payee.company_country_of_origin
                    && pp_form_required_fields_non_buyer_payee.company_country_of_origin.required
                    && (nonBuyerPayee.company.nationality === null || nonBuyerPayee.company.nationality === undefined)) {
                valid = false;
            }

            // Company Email
            if (pp_form_required_fields_non_buyer_payee.company_email
                    && pp_form_required_fields_non_buyer_payee.company_email.required) {
                if(!nonBuyerPayee.email_contact || !nonBuyerPayee.email_contact.value){
                    valid = false;
                }
            }

            // Company Contact Number
            if (pp_form_required_fields_non_buyer_payee.company_phone1
                    && pp_form_required_fields_non_buyer_payee.company_phone1.required
                    && (!nonBuyerPayee.phone1_contact || !nonBuyerPayee.phone1_contact.value)) {
                valid = false;
            }

            // Secondary Contact Number
            if (pp_form_required_fields_non_buyer_payee.company_phone2
                    && pp_form_required_fields_non_buyer_payee.company_phone2.required
                    && (!nonBuyerPayee.phone2_contact || !nonBuyerPayee.phone2_contact.value)) {
                valid =  false;
            }

            /** Address Validation **/
            if (valid){
                valid = self.isValidNonBuyerPayeeAddress(nonBuyerPayee, pp_form_required_fields_non_buyer_payee);
            }
        } else if (nonBuyerPayee.type === SHOWSUITE.BUYER_TYPE_INDIVIDUAL && pp_form_required_fields_non_buyer_payee) {
            if (!nonBuyerPayee.individual){
                console.warn("Malformed 3rd Party Payor individual object");
                return false;
            }

            // Salutation
            if (pp_form_required_fields_non_buyer_payee.title && pp_form_required_fields_non_buyer_payee.title.required
                    && !nonBuyerPayee.individual.title) {
                valid = false;
            }

            // Full Name as in NRIC/Passport
            if (pp_form_required_fields_non_buyer_payee.legal_name
                    && pp_form_required_fields_non_buyer_payee.legal_name.required
                    && !nonBuyerPayee.individual.legal_name) {
                valid = false;
            }

            // First Name
            if (pp_form_required_fields_non_buyer_payee.first_name
                    && pp_form_required_fields_non_buyer_payee.first_name.required
                    && !nonBuyerPayee.individual.first_name) {
                valid = false;
            }

            // Last Name(Surname)
            if (pp_form_required_fields_non_buyer_payee.last_name
                    && pp_form_required_fields_non_buyer_payee.last_name.required
                    && !nonBuyerPayee.individual.last_name) {
                valid = false;
            }

            // Industry
            if (pp_form_required_fields_non_buyer_payee.industry
                    && pp_form_required_fields_non_buyer_payee.industry.required
                    && !nonBuyerPayee.individual.industry) {
                valid = false;
            }

            // Occupation
            if (pp_form_required_fields_non_buyer_payee.occupation
                    && pp_form_required_fields_non_buyer_payee.occupation.required
                    && !nonBuyerPayee.individual.occupation) {
                valid = false;
            }

            // Gender
            if (pp_form_required_fields_non_buyer_payee.gender
                    && pp_form_required_fields_non_buyer_payee.gender.required
                    && (nonBuyerPayee.individual.gender === null || nonBuyerPayee.individual.gender === undefined)) {
                valid = false;
            }

            if(pp_form_required_fields_non_buyer_payee.identity_type
                    && pp_form_required_fields_non_buyer_payee.identity_type.required){
                // Identity Type
                if (!nonBuyerPayee.individual.identity_type) {
                    valid = false;
                } else {
                    if (nonBuyerPayee.individual.identity_type === SHOWSUITE.IDENTITY_TYPE_FOREIGNER) {
                        // Passport No
                        if (pp_form_required_fields_non_buyer_payee.passport_no
                                && pp_form_required_fields_non_buyer_payee.passport_no.required
                                && !nonBuyerPayee.individual.passport_no) {
                            valid = false;
                        }
                        // Foreigner National ID
                        if (pp_form_required_fields_non_buyer_payee.foreigner_national_id
                                && pp_form_required_fields_non_buyer_payee.foreigner_national_id.required
                                && !nonBuyerPayee.individual.foreigner_national_id) {
                            valid = false;
                        }
                        // Passport Issued By
                        if (pp_form_required_fields_non_buyer_payee.passport_issued_by
                                && pp_form_required_fields_non_buyer_payee.passport_issued_by.required
                                && !nonBuyerPayee.individual.passport_issued_by) {
                            valid = false;
                        }
                        // Passport Issued Date
                        if (pp_form_required_fields_non_buyer_payee.passport_issued_date
                                && pp_form_required_fields_non_buyer_payee.passport_issued_date.required
                                && !nonBuyerPayee.individual.passport_issued_date) {
                            valid = false;
                        }
                        // Passport Expiry Date
                        if (pp_form_required_fields_non_buyer_payee.passport_expiry_date
                                && pp_form_required_fields_non_buyer_payee.passport_expiry_date.required
                                && !nonBuyerPayee.individual.passport_expiry_date) {
                            valid = false;
                        }
                    } else if (nonBuyerPayee.individual.identity_type !== SHOWSUITE.IDENTITY_TYPE_FOREIGNER) {
                        // NRIC
                        if (pp_form_required_fields_non_buyer_payee.national_id && pp_form_required_fields_non_buyer_payee.national_id.required) {
                            if(!nonBuyerPayee.individual.national_id || nonBuyerPayee.individual.national_id.length < 4){
                                valid = false;
                            }
                        }
                    }
                }
            }

            // Nationality
            if (pp_form_required_fields_non_buyer_payee.nationality
                    && pp_form_required_fields_non_buyer_payee.nationality.required
                    && !nonBuyerPayee.individual.nationality) {
                valid = false;
            }

            // Country Of Birth
            if (pp_form_required_fields_non_buyer_payee.country_of_origin
                    && pp_form_required_fields_non_buyer_payee.country_of_origin.required
                    && !nonBuyerPayee.individual.country_of_origin) {
                valid = false;
            }

            // Date of Birth
            if(pp_form_required_fields_non_buyer_payee.dob && pp_form_required_fields_non_buyer_payee.dob.required){
                if (!nonBuyerPayee.individual.dob || moment().diff(moment(nonBuyerPayee.individual.dob, "DD/MM/YYYY")) < 0) {
                    valid = false;
                }
            }

            // Email
            if (pp_form_required_fields_non_buyer_payee.email
                    && pp_form_required_fields_non_buyer_payee.email.required
                    && !nonBuyerPayee.email_contact) {
                valid = false;
            }

            // Mobile Number
            if (pp_form_required_fields_non_buyer_payee.phone1
                    && pp_form_required_fields_non_buyer_payee.phone1.required
                    && !nonBuyerPayee.phone1_contact) {
                valid = false;
            }

            // Contact Number
            if (pp_form_required_fields_non_buyer_payee.phon2
                    && pp_form_required_fields_non_buyer_payee.phon2.required
                    && !nonBuyerPayee.phone2_contact) {
                valid = false;
            }

            // Relationship with Buyer 1
            if (!nonBuyerPayee.buyer_company_id){
                if (pp_form_required_fields_non_buyer_payee.relationship_with_buyer_1
                        && pp_form_required_fields_non_buyer_payee.relationship_with_buyer_1.required
                        && !nonBuyerPayee.individual.relationship) {
                    valid = false;
                }

                /** Address Validation **/
                if (valid){
                    valid = self.isValidNonBuyerPayeeAddress(nonBuyerPayee, pp_form_required_fields_non_buyer_payee);
                }
            }
        }

        return valid;
    }

    isValidNonBuyerPayeeAddress(nonBuyerPayee, pp_form_required_fields_non_buyer_payee){
        const self = this;
        let valid = true;

        if(pp_form_required_fields_non_buyer_payee.primary_address_postcode
                && pp_form_required_fields_non_buyer_payee.primary_address_postcode.required
                && (nonBuyerPayee.primary_address && nonBuyerPayee.primary_address.country === self.SINGAPORE_SELECTOR_ID)){
            if (!nonBuyerPayee.primary_address.postcode || nonBuyerPayee.primary_address.postcode.length !== 6) {
                valid = false;
            }
        }

        if (pp_form_required_fields_non_buyer_payee.primary_address_line1
                && pp_form_required_fields_non_buyer_payee.primary_address_line1.required
                && (!nonBuyerPayee.primary_address || !nonBuyerPayee.primary_address.line1)) {
            valid = false;
        }

        if (pp_form_required_fields_non_buyer_payee.primary_address_line2
                && pp_form_required_fields_non_buyer_payee.primary_address_line2.required
                && (!nonBuyerPayee.primary_address || !nonBuyerPayee.primary_address.line2)) {
            valid = false;
        }
        if(pp_form_required_fields_non_buyer_payee.primary_address_unit_no
                && pp_form_required_fields_non_buyer_payee.primary_address_unit_no.required
                && (!nonBuyerPayee.primary_address || !nonBuyerPayee.primary_address.residence_type)){
            if(!nonBuyerPayee.primary_address || !nonBuyerPayee.primary_address.unit_no){
                valid = false;
            }
        }

        if (pp_form_required_fields_non_buyer_payee.primary_address_city
                && pp_form_required_fields_non_buyer_payee.primary_address_city.required
                && (!nonBuyerPayee.primary_address || !nonBuyerPayee.primary_address.city)) {
            valid = false;
        }

        return valid;
    }

    getDocumentNameByRelation(non_buyer){
        const self = this;

        if(!non_buyer || (non_buyer && !non_buyer.individual)){
            return "";
        }

        if(non_buyer.individual.relationship && self.buyer_relationships){
            let relation = self.buyer_relationships.find(x => x.name == non_buyer.individual.relationship);
            if(relation && relation.document){
                return relation.document + " - ";
            }
        }

        return "";
    }

    // OTHER DOCUMENTS SECTION FOR EOI & BOOKING FLOW
    addDocument() {
        const self = this;

        let new_document = {};

        if(!self.eoi.documents){
            self.eoi.documents = [];
        }

        self.eoi.documents.push(new_document);

        self.$timeout(function() { 
            $(".eoi-select[id^=format]").niceSelect('update');
        });
    }

    removeDocument(document) {
        const self = this;

        self.hideModals();
        self.selected_document = document;
        
        self.showRemoveDocumentModal = true;
        $('#removeDocumentModal').modal('show');
    }

    confirmRemoveDocument(url) {
        const self = this;

        if (self.selected_document.hasOwnProperty("id") && self.selected_document.id != null) {
            loading(true);
            self.SSHttpService.getAPIRequest(url).then(function (response) {
                loading(false);

                if (response instanceof Error) {
                    self.SSAlertService.parseAndDisplayError(response);
                    return;
                }
                
                self.removeDocumentObject(self.selected_document);
            });
        } else {
            self.removeDocumentObject(self.selected_document);
        }
    }

    removeDocumentObject(other_document) {
        const self = this;

        self.hideModals();

        self.eoi.documents = self.eoi.documents.filter(item => item !== other_document);
        self.SSAlertService.success('Success!', 'Other Document successfully removed.');
    }

    uploadOtherDocument(files, other_document, upload_type, document_kind) {
        const self = this;

        if (!files || files.length < 1) return;
        let file = files[0];
        let format_type = null;

        if (file.type.indexOf("application/pdf") !== -1) {
            format_type = self.PROPERTY_DOCUMENT_FORMAT_PDF;
        } else if (file.type.indexOf("image") !== -1) {
            format_type = self.PROPERTY_DOCUMENT_FORMAT_IMG;
        } else {
            self.SSAlertService.danger('Error!', 'Please upload PDF or image file only');
            return;
        }

        let policyUrl = 'sec/eois/' + self.eoi.id + '/documents/s3-policy-document';

        loading(true);
        self.SSAwsService.uploadAPIRequest(file, policyUrl, function (response) {
            loading(false);
            if (response instanceof Error) {                
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            self.SSAlertService.success('Success!', 'File uploaded successfully.');

            other_document['file_uri'] = response.config.data.key;
            other_document.file = file;
            other_document.format = format_type;
            other_document.type = upload_type;
            self.documentUploadDirty = true;
        });
    }

    removeOtherDocument(documentObj, callback) {
        const self = this;

        if (documentObj.hasOwnProperty("id") && documentObj.id != null) {
            let url = 'sec/eois/' + self.eoi.id + '/documents/' + documentObj.id + '/delete';

            loading(true);
            self.SSHttpService.getAPIRequest(url).then(function (response) {
                loading(false);
                if (response instanceof Error) {
                    self.SSAlertService.parseAndDisplayError(response);
                    return;
                }
                self.SSAlertService.success('Success!', 'File deleted successfully.');

                documentObj.id = null;
                documentObj.file_uri = null;
                documentObj["show_file_url"] = null;
                documentObj.file = null;

                callback;
            });
        } else {
            //Haven't update EOI instruments
            documentObj.file_uri = null;
            documentObj["show_file_url"] = null;
            documentObj.file = null;
        }
    }

    replaceOtherDocument(files, upload_type, document_kind, documentObj) {
        const self = this;

        if (!files || files.length < 1) return;
        let file = files[0];
        let format_type = null;

        if (file.type.indexOf("application/pdf") !== -1) {
            format_type = self.PROPERTY_DOCUMENT_FORMAT_PDF;
        } else if (file.type.indexOf("image") !== -1) {
            format_type = self.PROPERTY_DOCUMENT_FORMAT_IMG;
        } else {
            self.SSAlertService.danger('Error!', 'Please upload PDF or image file only');
            return;
        }

        let callback = self.uploadOtherDocument(files, documentObj, upload_type, document_kind);

        self.removeEOIDocument(documentObj, callback);
    }

    removeEOIDocument(documentObj, callback) {
        const self = this;

        documentObj.file_uri = null;
        documentObj["show_file_url"] = null;
        documentObj.file = null;

        if (callback) {
            callback;
        }
    }

    uploadEOIOtherDocument() {
        const self = this;

        let url = '';

        $.each(self.eoi.documents, function (index, _other_document) {
            let other_document = angular.copy(_other_document);
            let params = {
                name: other_document.name,
                type: other_document.type ? other_document.type : SHOWSUITE.PROPERTY_DOCUMENT_TYPE_FORM,
                format: other_document.format,
                document_kind: self.PROPERTY_DOCUMENT_KIND_OTHER,
                file_uri: other_document.file_uri,
                non_buyer_payee_id: other_document.non_buyer_payee_id
            };

            loading(true);
            if (other_document.hasOwnProperty("id") && other_document.id != null) {
                url = 'sec/eois/' + self.eoi.id + '/documents/' + other_document.id;

                self.SSHttpService.putAPIRequest(url, params).then(function (response) {
                    if (response instanceof Error) {
                        loading(false);
                        self.SSAlertService.parseAndDisplayError(response);
                        return;
                    }

                    if (!self.eoi.hasOwnProperty("documents")) {
                        self.eoi["documents"] = {};
                    }
                    other_document = response.data.eoi_document;

                    //Prepare preview image for documents
                    url = 'sec/assets/presigned-url';
                    params = {
                        'model': SHOWSUITE.ASSET_MODEL_EOI_DOCUMENT,
                        'id': other_document.id
                    };
                    self.SSHttpService.getAPIRequest(url, params).then(function (response) {
                        loading(false);
                        if (response instanceof Error) {
                            self.SSAlertService.parseAndDisplayError(response);
                            return;
                        }

                        other_document["show_file_url"] = response.data.presigned_url;
                        self.eoi.documents[index] = other_document;
                    });
                });
            } else {
                url = 'sec/eois/' + self.eoi.id + '/documents';

                self.SSHttpService.postAPIRequest(url, params).then(function (response) {
                    if (response instanceof Error) {
                        loading(false);
                        self.SSAlertService.parseAndDisplayError(response);
                        return;
                    }

                    if (!self.eoi.hasOwnProperty("documents")) {
                        self.eoi["documents"] = {};
                    }
                    other_document = response.data.eoi_document;

                    //Prepare preview image for documents
                    url = 'sec/assets/presigned-url';
                    params = {
                        'model': SHOWSUITE.ASSET_MODEL_EOI_DOCUMENT,
                        'id': other_document.id
                    };
                    self.SSHttpService.getAPIRequest(url, params).then(function (response) {
                        loading(false);
                        if (response instanceof Error) {
                            self.SSAlertService.parseAndDisplayError(response);
                            return;
                        }

                        other_document["show_file_url"] = response.data.presigned_url;
                        self.eoi.documents[index] = other_document;
                    });
                });
            }
        });
    }
    // END SECTION ADDITIONAL DOCUMENTS

    // PRIMARY DOCUMENTS SECTION
    uploadNewDocumentFiles(files, file_name, upload_type, document_kind, buyer) {
        const self = this;

        if (!files || files.length < 1) return;
        let file = files[0];
        let format_type = null;

        if (file.type.indexOf("application/pdf") !== -1) {
            format_type = self.PROPERTY_DOCUMENT_FORMAT_PDF;
        } else if (file.type.indexOf("image") !== -1) {
            format_type = self.PROPERTY_DOCUMENT_FORMAT_IMG;
        } else {
            self.SSAlertService.danger('Error!', 'Please upload PDF or image file only');
            return;
        }

        self.file = {
            'files': files,
            'file_name': file_name,
            'upload_type': upload_type,
            'document_kind': document_kind,
            'buyer': buyer,
            'callback': self.uploadNewDocCallback
        };

        // https://showsuite.atlassian.net/browse/WEB-2209
        // There should not be any developer code required popup box for the documents upload section
        self.file.callback(self);
    }

    uploadNewDocCallback(self) {
        self.uploadDocumentFiles(self.file.files, self.file.file_name, self.file.upload_type, self.file.document_kind, self.file.buyer);
    }

    uploadDocumentFiles(files, file_name, upload_type, document_kind, buyer) {
        const self = this;

        if (!files || files.length < 1) return;
        let file = files[0];
        let format_type = null;

        if (file.type.indexOf("application/pdf") !== -1) {
            format_type = self.PROPERTY_DOCUMENT_FORMAT_PDF;
        } else if (file.type.indexOf("image") !== -1) {
            format_type = self.PROPERTY_DOCUMENT_FORMAT_IMG;
        } else {
            self.SSAlertService.danger('Error!', 'Please upload PDF or image file only');
            return;
        }

        let policyUrl = 'sec/buyers/s3-policy-document';

        if (self.timeout_doc_promise) {
            self.$timeout.cancel(self.timeout_doc_promise);
        }

        self.timeout_doc_promise = self.$timeout(function () {
            self.displayDocumentUploadTimeup();
        }, 30000); // 30 sec delay

        loading(true);
        self.SSAwsService.uploadAPIRequest(file, policyUrl, function (response) {

            if (response instanceof Error) {
                loading(false);
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            self._uploadDocument(response.config.data.key, file_name, upload_type, format_type, document_kind, buyer);
        });
    }

    _uploadDocument(file_uri, file_name, upload_type, format_type, document_kind, buyer) {
        const self = this;

        let url = 'sec/buyers/' + buyer.id + '/documents';
        let params = {
            name: file_name,
            type: upload_type,
            format: format_type,
            document_kind: document_kind,
            file_uri: file_uri,
            authorisation_code: self.buyer_doc_verification_code
        };

        self.SSHttpService.postAPIRequest(url, params).then(function (response) {
            if (response instanceof Error) {
                loading(false);
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }
            self.SSAlertService.success('Success!', 'File uploaded successfully.');

            if (!buyer.hasOwnProperty("documents")) {
                buyer["documents"] = {};
            }
            buyer["documents"][document_kind] = response.data.buyer_document;

            //To retrieve preview image
            url = 'sec/assets/presigned-url';
            params = {
                'model': self.ASSET_MODEL_BUYER_DOCUMENT,
                'id': response.data.buyer_document.id
            };
            self.SSHttpService.getAPIRequest(url, params).then(function (response) {
                loading(false);
                if (response instanceof Error) {
                    self.SSAlertService.parseAndDisplayError(response);
                    return;
                }

                buyer["documents"][document_kind]["show_file_url"] = response.data.presigned_url;
                self.documentUploadDirty = true;
                if (self.timeout_doc_promise) {
                    self.$timeout.cancel(self.timeout_doc_promise);
                }
            });

        });
    }

    displayDocumentUploadTimeup() {
        const self = this;

        loading(false);

        self.hideModals();

        self.showDocumentUploadTimeup = true;
        $('#documentUploadTimeupModal').modal({
            show: true,
            backdrop: 'static',
            keyboard: false
        });
    }

    removeBuyerDocCallBack(self) {
        let url = 'sec/buyers/' + self.file.documentObj.buyer_id + '/documents/' + self.file.documentObj.id + '/delete';

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }
            self.SSAlertService.success('Success!', 'File deleted successfully.');

            self.file.buyer["documents"][self.file.documentObj.document_kind] = null;
        });
    }

    removeBuyerDocument(documentObj, buyer) {
        const self = this;

        self.file = {
            'buyer': buyer,
            'documentObj': documentObj,
            'callback': self.removeBuyerDocCallBack
        };

        // https://showsuite.atlassian.net/browse/WEB-2209
        // There should not be any developer code required popup box for the documents upload section
        self.file.callback(self);
    }


    replaceBuyerDocCallBack(self) {
        //Delete existing file
        let url = 'sec/buyers/' + self.file.documentObj.buyer_id + '/documents/' + self.file.documentObj.id + '/delete';

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            if (response instanceof Error) {
                loading(false);
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            self.file.buyer["documents"][self.file.documentObj.document_kind] = null;
            self.uploadDocumentFiles(self.file.files, self.file.file_name, self.file.upload_type, self.file.document_kind, self.file.buyer);
        });
    }

    replaceDocumentFiles(files, file_name, upload_type, document_kind, buyer, documentObj) {
        const self = this;

        if (!files || files.length < 1) return;
        let file = files[0];
        let format_type = null;

        if (file.type.indexOf("application/pdf") !== -1) {
            format_type = self.PROPERTY_DOCUMENT_FORMAT_PDF;
        } else if (file.type.indexOf("image") !== -1) {
            format_type = self.PROPERTY_DOCUMENT_FORMAT_IMG;
        } else {
            self.SSAlertService.danger('Error!', 'Please upload PDF or image file only');
            return;
        }

        self.file = {
            'files': files,
            'file_name': file_name,
            'upload_type': upload_type,
            'document_kind': document_kind,
            'buyer': buyer,
            'documentObj': documentObj,
            'callback': self.replaceBuyerDocCallBack
        };

        // https://showsuite.atlassian.net/browse/WEB-2209
        // There should not be any developer code required popup box for the documents upload section

        self.file.callback(self);
    }

    uploadNewBeneficiaryDocumentFiles(files, file_name, upload_type, document_kind, beneficiary) {
        const self = this;

        if (!files || files.length < 1) return;
        let file = files[0];
        let format_type = null;

        if (file.type.indexOf("application/pdf") !== -1) {
            format_type = self.PROPERTY_DOCUMENT_FORMAT_PDF;
        } else if (file.type.indexOf("image") !== -1) {
            format_type = self.PROPERTY_DOCUMENT_FORMAT_IMG;
        } else {
            self.SSAlertService.danger('Error!', 'Please upload PDF or image file only');
            return;
        }

        self.file = {
            'files': files,
            'file_name': file_name,
            'upload_type': upload_type,
            'document_kind': document_kind,
            'beneficiary': beneficiary,
            'callback': self.uploadNewBeneficiaryDocCallback
        };

        // https://showsuite.atlassian.net/browse/WEB-2209
        // There should not be any developer code required popup box for the documents upload section
        self.file.callback(self);

        // if (self.no_need_developer_code) {
        //     self.file.callback(self);
        // } else {
        //     //Request developer code
        //     self.requestDeveloperCode();
        // }
    }

    uploadNewBeneficiaryDocCallback(self) {
        self.uploadBeneficiaryDocumentFiles(self.file.files, self.file.file_name, self.file.upload_type, self.file.document_kind, self.file.beneficiary);
    }

    uploadBeneficiaryDocumentFiles(files, file_name, upload_type, document_kind, beneficiary) {
        const self = this;

        if (!files || files.length < 1) return;
        let file = files[0];
        let format_type = null;

        if (file.type.indexOf("application/pdf") !== -1) {
            format_type = self.PROPERTY_DOCUMENT_FORMAT_PDF;
        } else if (file.type.indexOf("image") !== -1) {
            format_type = self.PROPERTY_DOCUMENT_FORMAT_IMG;
        } else {
            self.SSAlertService.danger('Error!', 'Please upload PDF or image file only');
            return;
        }

        let policyUrl = 'sec/eois/' + self.eoi.id + '/documents/s3-policy-document';

        if (self.timeout_doc_promise) {
            self.$timeout.cancel(self.timeout_doc_promise);
        }

        self.timeout_doc_promise = self.$timeout(function () {
            self.displayDocumentUploadTimeup();
        }, 30000); // 30 sec delay

        loading(true);
        self.SSAwsService.uploadAPIRequest(file, policyUrl, function (response) {

            if (response instanceof Error) {
                loading(false);
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            self._uploadBeneficiaryDocument(response.config.data.key, file_name, upload_type, format_type, document_kind, beneficiary);
        });
    }

    _uploadBeneficiaryDocument(file_uri, file_name, upload_type, format_type, document_kind, beneficiary) {
        const self = this;

        let url = 'sec/eois/' + self.eoi.id + '/documents';
        let params = {
            name: file_name,
            type: upload_type,
            format: format_type,
            document_kind: document_kind,
            file_uri: file_uri,
            authorisation_code: self.buyer_doc_verification_code,
            beneficiary_id: beneficiary.id
        };

        self.SSHttpService.postAPIRequest(url, params).then(function (response) {
            if (response instanceof Error) {
                loading(false);
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }
            self.SSAlertService.success('Success!', 'File uploaded successfully.');

            if (!beneficiary.hasOwnProperty("documents")) {
                beneficiary["documents"] = {};
            }
            beneficiary["documents"][document_kind] = response.data.eoi_document;

            //To retrieve preview image
            url = 'sec/assets/presigned-url';
            params = {
                'model': SHOWSUITE.ASSET_MODEL_EOI_DOCUMENT,
                'id': response.data.eoi_document.id
            };
            self.SSHttpService.getAPIRequest(url, params).then(function (response) {
                loading(false);
                if (response instanceof Error) {
                    self.SSAlertService.parseAndDisplayError(response);
                    return;
                }

                beneficiary["documents"][document_kind]["show_file_url"] = response.data.presigned_url;
                self.documentUploadDirty = true;
                if (self.timeout_doc_promise) {
                    self.$timeout.cancel(self.timeout_doc_promise);
                }
            });

        });
    }

    removeBeneficiaryDocCallBack(self) {
        let url = 'sec/eois/' + self.eoi.id + '/documents/' + self.file.documentObj.id + '/delete';

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            loading(false);

            if (response instanceof Error) {
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }
            self.SSAlertService.success('Success!', 'File deleted successfully.');

            self.file.beneficiary["documents"][self.file.documentObj.document_kind] = null;
        });
    }

    removeBeneficiaryDocument(documentObj, beneficiary) {
        const self = this;

        self.file = {
            'beneficiary': beneficiary,
            'documentObj': documentObj,
            'callback': self.removeBeneficiaryDocCallBack
        };

        // https://showsuite.atlassian.net/browse/WEB-2209
        // There should not be any developer code required popup box for the documents upload section

        self.file.callback(self);
    }

    replaceBeneficiaryDocCallBack(self) {
        //Delete existing file
        let url = 'sec/eois/' + self.eoi.id + '/documents/' + self.file.documentObj.id + '/delete';

        loading(true);
        self.SSHttpService.getAPIRequest(url).then(function (response) {
            if (response instanceof Error) {
                loading(false);
                self.SSAlertService.parseAndDisplayError(response);
                return;
            }

            self.file.beneficiary["documents"][self.file.documentObj.document_kind] = null;
            self.uploadBeneficiaryDocumentFiles(self.file.files, self.file.file_name, self.file.upload_type, self.file.document_kind, self.file.beneficiary);
        });
    }

    replaceBeneficiaryDocumentFiles(files, file_name, upload_type, document_kind, beneficiary, documentObj) {
        const self = this;

        if (!files || files.length < 1) return;
        let file = files[0];
        let format_type = null;

        if (file.type.indexOf("application/pdf") !== -1) {
            format_type = self.PROPERTY_DOCUMENT_FORMAT_PDF;
        } else if (file.type.indexOf("image") !== -1) {
            format_type = self.PROPERTY_DOCUMENT_FORMAT_IMG;
        } else {
            self.SSAlertService.danger('Error!', 'Please upload PDF or image file only');
            return;
        }

        self.file = {
            'files': files,
            'file_name': file_name,
            'upload_type': upload_type,
            'document_kind': document_kind,
            'beneficiary': beneficiary,
            'documentObj': documentObj,
            'callback': self.replaceBeneficiaryDocCallBack
        };

        // https://showsuite.atlassian.net/browse/WEB-2209
        // There should not be any developer code required popup box for the documents upload section
        self.file.callback(self);
    }

    hideModals(){
        const self = this;

        self.showRemoveDocumentModal = false;

        if ($('#removeDocumentModal').is(':visible')) $('#removeDocumentModal').modal('hide');
    }

    getQuestions(entity_type, phase_type, group, property_questions, isFirstBuyer=false) {
        const self = this;

        if (!property_questions
            || (property_questions
                && property_questions.length == 0)) {
            return null;
        }

        let filtered_questions = [];
        let all_questions = angular.copy(property_questions);

        $.each(all_questions, function (index, question) {
            if (question.type == SHOWSUITE.QUESTION_TYPE_SELECT
                || question.type == SHOWSUITE.QUESTION_TYPE_CHECKBOX
                || question.type == SHOWSUITE.QUESTION_TYPE_RADIO
            ) {
                question.formatted_choices = [];
                $.each(JSON.parse(question.choices), function (j, choice) {
                    question.formatted_choices.push({
                        label: choice,
                        answer: false
                    });
                });
            }
            let is_group_match = false;
            if(entity_type === self.QUESTION_ENTITY_TYPE_BROKER_TEAM_LEAD ||
            entity_type === self.QUESTION_ENTITY_TYPE_DEVELOPER){
                is_group_match = true;
            }else{
                if (question.group === group){
                    /** Show if group matches **/
                    is_group_match = true;
                } else{
                    if (!question.group){
                        /** Show question if question group All and passed group not signatory **/
                        is_group_match = true;
                    }
                    else if ((group === self.QUESTION_GROUP_SIGNATORY || group === self.QUESTION_GROUP_INDIVIDUAL)
                        && question.group === self.QUESTION_GROUP_BOTH_INDIVIDUAL_N_SIGNATORY){
                        /** Show question if question group (Both Individual and Signatory) and passed group is individual or signatory **/
                        is_group_match = true;
                    }
                    else if ((group === self.QUESTION_GROUP_COMPANY || group === self.QUESTION_GROUP_INDIVIDUAL)
                        && question.group ===  self.QUESTION_GROUP_BOTH_INDIVIDUAL_N_COMPANY){
                        /** Show question if question group (Both Individual and Company) and passed group is individual or company **/
                        is_group_match = true;
                    }
                }
            }
            if ((!question.entity_type || question.entity_type === entity_type)
                && (!question.phase_type || question.phase_type === phase_type)
                && (is_group_match)
                && (question.entity_type !== SHOWSUITE.QUESTION_ENTITY_TYPE_BUYER || !(question.is_first_buyer_only) || isFirstBuyer)
            ) {
                filtered_questions.push(question);
            }

        });

        return filtered_questions;
    }

    getQuestionAnswers(questions) {
        const self = this;

        let answers = {};

        $.each(questions, function (index, question) {
            answers[question.id] = self.getQuestionAnswer(question);
        });

        return answers;
    }

    getQuestionAnswer(question) {
        const self = this;

        if (!question) return;

        let answerText = null;

        if (question.type == SHOWSUITE.QUESTION_TYPE_CHECKBOX) {
            // Checkboxes can have multiple answers
            let answer_array = [];
            if (question.formatted_choices) {
                $.each(question.formatted_choices, function (i, choice) {
                    if (choice.answer) {
                        answer_array.push(choice.label);
                    }
                });
            }
            answerText = answer_array ? JSON.stringify(answer_array) : '';
        } else {
            answerText = question.answer_text ? question.answer_text : '';
        }

        return answerText;
    }

    setQuestionAnswer(question) {
        const self = this;
        if (!question) return;
        question.answer_text = self.getQuestionAnswer(question);
    }

    renderHtml(question) {
        const self = this;

        let content = question.question_text;

        if (question.is_required === 1) {
            let splitted_string = content.split("</p>");
            if (splitted_string.length > 1) {
                content = "";
                $.each(splitted_string, function (index, split) {
                    if (index === splitted_string.length - 1) {
                        content += " <span class='text-danger' style='display: inline'>*</span>";
                    }

                    content += split;
                });
            } else {
                splitted_string = content.split("</blockquote>");
                if (splitted_string.length > 1) {
                    content = "";
                    $.each(splitted_string, function (index, split) {
                        if (index === splitted_string.length - 1) {
                            content += " <span class='text-danger' style='display: inline'>*</span>";
                        }

                        content += split;
                    });
                } else {
                    content += "<span class='text-danger' style='display: inline'>*</span>";
                }
            }
            
        }

        return self.$sce.trustAsHtml(content);
    }

    validateQuestionAnswers(questions) {
        const self = this;

        let isValid = true;

        $.each(questions, function (index, question) {
            question.error = "";
            if (question.is_required === 1
                && (question.answer_text === null
                    || question.answer_text === ""
                    || question.answer_text === undefined
                    || question.answer_text === []
                    || question.answer_text === "[]"
                )
            ) {
                self.findParentQuestion(questions, question);

                if (question.type == SHOWSUITE.QUESTION_TYPE_CHECKBOX) {
                    let answered = false;

                    $.each(question.formatted_choices, function (counter, choice) {
                        if (choice.answer) {
                            answered = true;
                            return false;
                        }
                    });

                    if (!answered) {
                        isValid = false;
                        question.error = "Answer is required.";
                    } else if (question.parent) {
                        if (self.parentQuestionAnswerMatches(question)) {
                            isValid = false;
                            question.error = "Answer is required.";
                        }
                    }
                } else {
                    if (question.parent) {
                        if (self.parentQuestionAnswerMatches(question)) {
                            isValid = false;
                            question.error = "Answer is required.";
                        }
                    } else {
                        isValid = false;
                        question.error = "Answer is required.";
                    }
                }
            }
        });

        return isValid;
    }

    findParentQuestion(questions, question) {
        if (!questions && !question) return;

        if (question.parent_question_id) {
            question.parent = questions.find(x => x.id == question.parent_question_id);
        }

        return question.parent;
    }

    parentQuestionAnswerMatches(question) {
        if (!question) return;
        if (!(question.parent_question_id)) return true; // Means, it has no parent. Should always return true.
        if (!(question.parent_question_answer)) return;

        if (!(question.parent)) return; // Should be set already. TODO: If not, find and set here.
        if (!(question.parent.answer_text)) return;

        // Process parent's answer
        let answers = [];
        try {
            answers = JSON.parse(question.parent.answer_text);
        } catch (e) { // Means is a plain text
            answers = [question.parent.answer_text];
        }

        try{
            answers = answers.map(o => o.toUpperCase().trim()); // change to uppercase for check
        }catch(e){}

        if(answers && answers.length && question.parent_question_answer){
            return answers.indexOf(question.parent_question_answer.toUpperCase().trim()) > -1; // Match both in uppercase
        } else if(answers && question.parent_question_answer){ // match for number
            return answers == question.parent_question_answer;
        } else{
            return false
        }

    }

    updateBuyerQuestions(url, buyer_id, question) {
        const self = this;

        if (question.type == SHOWSUITE.QUESTION_TYPE_CHECKBOX) {
            //Process answer
            let answer_array = [];
            $.each(question.formatted_choices, function (i, choice) {
                if (choice.answer) {
                    answer_array.push(choice.label);
                }
            });                            
            question.answer_text = JSON.stringify(answer_array);
        }

        if (question.hasOwnProperty("answer_text")
            && question.answer_text !== null
            && question.answer_text !== ''
            && question.answer_text !== undefined
            && question.answer_text !== []
        ) {
            let params = {
                'buyer_id': buyer_id,
                'property_question_id': question.id,
                'answer_text': question.answer_text
            };

            return {
                then: function (callback) {
                    if (!params.id) {
                        self.SSHttpService.postAPIRequest(url, params).then(function (response) {
                            callback(response);
                        });
                    } else {
                        self.SSHttpService.putAPIRequest(url, params).then(function (response) {
                            callback(response);
                        });
                    }
                }
            }
        }

        return { // else return empty
            then: function (callback) {
                callback();
            }
        }
    }

    updateNonBuyerQuestions(url, buyer_id, question) {
        const self = this;

        if (question.type == SHOWSUITE.QUESTION_TYPE_CHECKBOX) {
            //Process answer
            let answer_array = [];
            $.each(question.formatted_choices, function (i, choice) {
                if (choice.answer) {
                    answer_array.push(choice.label);
                }
            });                            
            question.answer_text = JSON.stringify(answer_array);
        }

        if (question.hasOwnProperty("answer_text")
            && question.answer_text !== null
            && question.answer_text !== ''
            && question.answer_text !== undefined
            && question.answer_text !== []
        ) {
            let params = {
                'buyer_id': buyer_id,
                'property_question_id': question.id,
                'answer_text': question.answer_text
            };

            return {
                then: function (callback) {
                    if (!params.id) {
                        self.SSHttpService.postAPIRequest(url, params).then(function (response) {
                            callback(response);
                        });
                    } else {
                        self.SSHttpService.putAPIRequest(url, params).then(function (response) {
                            callback(response);
                        });
                    }
                }
            }
        }

        return { // else return empty
            then: function (callback) {
                callback();
            }
        }
    }

    /** WEB-2811 AMS/DMS : AUS country support **/
    getNRICLabel(country){
        const self = this;

        let nricLabel = "National ID No.";
        if(country === self.SSUtilService.COUNTRY_ID.SINGAPORE){
            nricLabel = "NRIC"; // {!! getLangWord('000330') !!}
        } else if(country === self.SSUtilService.COUNTRY_ID.AUSTRALIA){
            nricLabel = "National Id/Passport No.";
        }

        return nricLabel
    }

    previewDocument(document){
        const self = this;

        if(document.show_file_url){
            window.open(document.show_file_url, '_blank');
        }else{
            console.warn("File URI does not exist.")
        }
    }

    /** WEB-2937 Corporate Sale - Support for Multiple Signatories - Start **/
    getBuyerTypeName(type) {
        const self = this;

        let buyerType = self.BUYER_TYPES.find(t => t.id === type);
        return buyerType? buyerType.name : null;
    }

    initSignatorySettingsIfEmpty(form_settings){
        const self = this;

        try{
            let signatory_settings = {
                signatory_title: "title",
                signatory_legal_name: "legal_name",
                signatory_first_name: "first_name",
                signatory_last_name: "last_name",
                signatory_industry: "industry",
                signatory_occupation: "occupation",
                signatory_gender: "gender",
                signatory_identity_type: "identity_type",
                signatory_national_id: "national_id",
                signatory_race: "race",
                signatory_passport_no: "passport_no",
                signatory_foreigner_national_id: "foreigner_national_id",
                signatory_passport_issued_by: "passport_issued_by",
                signatory_passport_issued_date: "passport_issued_date",
                signatory_passport_expiry_date: "passport_expiry_date",
                signatory_nationality: "nationality",
                signatory_country_of_origin: "country_of_origin",
                signatory_monthly_income: "monthly_income",
                signatory_employment_status: "employment_status",
                signatory_education_level: "education_level",
                signatory_marital_status: "marital_status",
                signatory_spouse_national_id: "spouse_national_id",
                signatory_dob: "dob",
                signatory_email: "email",
                signatory_phone1: "phone1",
                signatory_phon2: "phon2",
                signatory_residence_type: "residence_type",
            }

            // Init signatory setting fields with individual setting fields if it doesn't exist
            form_settings.signatory_designation = form_settings.signatory_designation || {label: "Signatory Designation", required: true, visible: true};
            Object.keys(signatory_settings).forEach( signatory_setting_key => {
                if(!form_settings[signatory_setting_key]){ // If signatory setting empty
                    if(form_settings[signatory_settings[signatory_setting_key]]){ // If individual setting exist set for signatory
                        form_settings[signatory_setting_key] = form_settings[signatory_settings[signatory_setting_key]];
                    }else { // set default
                        form_settings[signatory_setting_key] = {label: "--", required: false, visible: false};
                    }
                }
            })

            self.form_settings = form_settings;
        }catch (e) {
            console.warn("An Error occurred trying to initialize Signatory Settings")
        }

        return form_settings;
    }

    isAddSignatoryAllowed(company){
        const self = this;

        let isAllowed = true;
        if(self.buyers && self.buyers.length){
            let signatory_buyers = [];
            if(self.buyers[0].type !== SHOWSUITE.BUYER_TYPE_COMPANY || !company
                    || company.type !== SHOWSUITE.BUYER_TYPE_COMPANY){
                isAllowed = false; // Do not allow if buyer type is not company
            }else{
                let company_signatories = self.buyers.filter(b => {
                    return b.type === SHOWSUITE.BUYER_TYPE_INDIVIDUAL && b.buyer_company_id && b.buyer_company_id === company.id
                });

                if(self.isMaxSignatoryLimitReached()){
                    // Do not allow more than 1 signatory for a company if corporate_purchase_multiple_signatories_support is not set
                    // Do not allow more than 6 total signatories
                    isAllowed = false;
                } else if(company_signatories && company_signatories.length && self.isMaxCompanyLimitReached()){
                    // Do not allow add signatory for company if it already has signatories and max number of companies has been added
                    // This ensures each company gets to add at least 1 signatory
                    isAllowed = false;
                }
            }
        }

        return isAllowed;
    }

    isMaxSignatoryLimitReached(){
        const self = this;

        let limitReached = false;
        if(self.buyers && self.buyers.length && self.buyers[0].type === SHOWSUITE.BUYER_TYPE_COMPANY){
            // Max signatory limit reached if multiple_signatories_support is disabled and 1 signatory added
            // Max signatory limit reached if 6 signatories added
            let signatory_buyers = self.buyers.filter(b => b.type === SHOWSUITE.BUYER_TYPE_INDIVIDUAL)
            if(signatory_buyers && signatory_buyers.length
                && ((!self.corporate_purchase_multiple_signatories_support.enabled && signatory_buyers.length >= 1)
                    || signatory_buyers.length >= 6)){
                limitReached = true;
            }
        }

        return limitReached;
    }

    isMaxCompanyLimitReached(){
        const self = this;

        let limitReached = false;
        if(self.buyers && self.buyers.length && self.buyers[0].type === SHOWSUITE.BUYER_TYPE_COMPANY){
            let max_signatories = 6;
            let companies = self.buyers.filter(b => b.type === SHOWSUITE.BUYER_TYPE_COMPANY);
            let signatory_buyers = self.buyers.filter(b => b.type === SHOWSUITE.BUYER_TYPE_INDIVIDUAL);
            let remaining_signatories = max_signatories - signatory_buyers.length;
            let companies_without_signatory = self.getCompaniesWithoutSignatory();
            if((companies
                && ((!self.corporate_purchase_multiple_signatories_support.enabled && companies.length >= 1)
                    || companies.length >= 6))
                || self.isMaxSignatoryLimitReached()){
                // Allow only one company if multiple_signatories_support check disabled
                // Allow max 6 companies
                // Do not allow to add company if max signatories added
                limitReached = true;
            }else if((remaining_signatories - (companies_without_signatory.length)) <= 0) {
                // Do not allow to add company if # of companies without signatory matches or exceeds remaining allowed signatories
                limitReached = true;
            }
        }

        return limitReached;
    }

    getSignatoryCompanyName(signatory, hidePostfix = false, buyers){
        const self = this;

        let buyersList = self.buyers;
        if(buyers){
            buyersList = buyers.map(b => {
                b = b.data || b;
                return b;
            })
        }

        let company_name = "";
        if(signatory && signatory.buyer_company_id && buyersList && buyersList.length){
            let signatory_company = buyersList.find(b => b.id === signatory.buyer_company_id)
            if(signatory_company){
                company_name = signatory_company.company? signatory_company.company.name: "--"
                if(!hidePostfix){
                    company_name += " - ";
                }
            }
        }

        return company_name;
    }

    getSignatoriesAgainstCompanyId(company_id, buyers){
        const self = this;

        let buyersList = self.buyers;
        if(buyers){
            buyersList = buyers.map(b => {
                b = b.data || b;
                return b;
            })
        }

        let signatories = null;
        if(company_id && buyersList && buyersList.length){
            signatories = buyersList.filter(b => b.type === SHOWSUITE.BUYER_TYPE_INDIVIDUAL && b.buyer_company_id === company_id);
        }

        return signatories; // Note: returns references to objects, use angular.copy for cloning
    }

    getCompaniesWithoutSignatory(buyers){
        const self = this;

        let buyersList = self.buyers;
        if(buyers){
            buyersList = buyers.map(b => {
                b = b.data || b;
                return b;
            })
        }

        let companies_without_signatory = [];
        if(buyersList && buyersList.length){
            companies_without_signatory = buyersList.filter(b => {
                if(b.type === SHOWSUITE.BUYER_TYPE_COMPANY){
                    let signatories = self.getSignatoriesAgainstCompanyId(b.id, buyers);
                    if(!signatories || !signatories.length){
                        return true;
                    }
                }
                return false; // skip
            })
        }

        return companies_without_signatory;
    }

    getAllCompanies(buyers){
        const self = this;

        let buyersList = self.buyers;
        if(buyers){
            buyersList = buyers.map(b => {
                b = b.data || b;
                return b;
            })
        }

        let companies = [];
        if (buyersList && buyersList.length){
            companies = buyersList.filter(b => {
                return b.type === SHOWSUITE.BUYER_TYPE_COMPANY;
            })
        }

        return companies;
    }

    getCompanyIndex(company, buyers){
        const self = this;

        let buyersList = self.buyers;
        if(buyers){
            buyersList = buyers.map(b => {
                b = b.data || b;
                return b;
            })
        }

        let index = 1;
        if(company && buyersList && buyersList.length){
            let companies = buyersList.filter(b => b.type === SHOWSUITE.BUYER_TYPE_COMPANY);
            if(companies && companies.length){
                index = companies.findIndex(c => c.id === company.id) +1;
            }
        }

        return index;
    }

    getSignatoryIndex(signatory, buyers){
        const self = this;

        let buyersList = self.buyers;
        if(buyers){
            buyersList = buyers.map(b => {
                b = b.data || b;
                return b;
            })
        }

        let index = 1;
        if(signatory && signatory.buyer_company_id && buyersList && buyersList.length){
            let company_signatories = self.getSignatoriesAgainstCompanyId(signatory.buyer_company_id, buyers);
            if(company_signatories && company_signatories.length){
                index = company_signatories.findIndex(s => s.id === signatory.id) + 1;
            }
        }

        return index;
    }

    getNextIndexForAddingSignatory(company_id, buyers){
        const self = this;

        let buyersList = self.buyers;
        if(buyers){
            buyersList = buyers.map(b => {
                b = b.data || b;
                return b;
            })
        }

        let index = 1;
        if(company_id && buyersList && buyersList.length){
            let company_signatories = self.getSignatoriesAgainstCompanyId(company_id, buyers);
            if(company_signatories && company_signatories.length){
                // Get last signatory index against company id if exists
                index = (buyersList.findIndex(b => b.id === company_signatories[company_signatories.length-1].id)) + 1;
            } else{
                // Get company index if company has no signatories
                index = buyersList.findIndex(b => b.id === company_id) + 1;
            }
        }

        return index;
    }

    updateSignatoryCompany(signatory_id, updated_company_id){
        const self = this;

        if (signatory_id && updated_company_id && self.buyers && self.buyers.length){
            let signatory_index = self.buyers.findIndex(b => b.id === signatory_id);
            let updated_index = self.getNextIndexForAddingSignatory(updated_company_id);
            if (updated_index > signatory_index){ // When moving signatory the signatory's index need to be
                updated_index -= 1;
            }

            self.array_move(self.buyers, signatory_index, updated_index);
        }
    }

    /** 3rd Party Payor Section - Start **/
    getNonBuyerPayeeCompanyIndex(company){
        const self = this;

        return self.getCompanyIndex(company, self.nonBuyerPayees);
    }

    getNonBuyerPayeeSignatoryIndex(signatory){
        const self = this;

        return self.getSignatoryIndex(signatory, self.nonBuyerPayees)
    }

    getNonBuyerPayeeIndex(nonBuyerPayee_id){
        const self = this;

        let index = 1;
        if(nonBuyerPayee_id && self.nonBuyerPayees && self.nonBuyerPayees.length){
            let nonBuyerPayeeIndividuals = self.nonBuyerPayees.filter(b => !b.buyer_company_id);
            if(nonBuyerPayeeIndividuals && nonBuyerPayeeIndividuals.length){
                index = nonBuyerPayeeIndividuals.findIndex(nbpi => nbpi.id === nonBuyerPayee_id) +1;
            }
        }

        return index;
    }

    getAllNonBuyerPayeeCompanies(){
        const self = this;

        return self.getAllCompanies(self.nonBuyerPayees);
    }

    getNextIndexForAddingNonBuyerPayeeSignatory(company_id){
        const self = this;

        return self.getNextIndexForAddingSignatory(company_id, self.nonBuyerPayees);
    }

    isMaxNonBuyerPayeeSignatoryLimitReached(company_id){
        const self = this;

        let company_signatories = self.getSignatoriesAgainstCompanyId(company_id, self.nonBuyerPayees);
        if(!company_signatories ||
            ((self.corporate_purchase_multiple_signatories_support.enabled && company_signatories.length < 6)
                || company_signatories.length < 1)){
            return false;
        }

        return true;
    }

    getNonBuyerPayeesArrIndex(nonBuyerPayee_id){
        const self = this;

        if(nonBuyerPayee_id && self.nonBuyerPayees && self.nonBuyerPayees.length){
            let nonBuyerPayeeIndex = self.nonBuyerPayees.findIndex(nbp => nbp.id === nonBuyerPayee_id)
            return (nonBuyerPayeeIndex && nonBuyerPayeeIndex >= 0) ? nonBuyerPayeeIndex : 0;
        }

        return 0;
    }
    /** 3rd Party Payor Section - End **/

    getDisplayNameForRelationship(entity) {
        const self = this;

        if(!entity){ return "--" }

        return entity.individual? entity.individual.legal_name : entity.company ? entity.company.name : '--';
    }

    getDisplayOptionsForRelationship(non_buyer_payees) {
        const self = this;

        if(!non_buyer_payees || !non_buyer_payees.length){ return []; }

        return non_buyer_payees.filter(b => !b.buyer_company_id);
    }
    /** WEB-2937 Corporate Sale - Support for Multiple Signatories - End **/
}

SSEoiService.$inject = ['$sce', '$timeout', 'SSConfigService', 'SSAlertService', 'SSHttpService', 'SSAwsService', 'SSUtilService'];
app.service('SSEoiService', SSEoiService);
